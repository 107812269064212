import React, { Component } from 'react';
import FontAwesome from "../FontAwesome/FontAwesome";
import styled from 'styled-components';
import { path } from 'ramda';
import { Link } from 'react-router-dom';
import UserCard from '../UserCard/UserCard';
import ViewHeader from '../ViewHeader/ViewHeader';
import { tenantsForLandlord } from '../../services/AppService';
import { withGlobal } from '../../ui/Global/Global';
import Toast from "../../shared/toast";

class LandlordTenants extends Component {
  state = {
    landlordID: null,
    property: null,
    tenants: null,
    propertyID: null
  };

  async componentWillMount() {
    const propertyID = path(['match', 'params', 'propertyID'], this.props);
    const { currentUser } = this.props.context;
    const resp = await tenantsForLandlord({
      landlordID: currentUser.user_id,
      propertyID,
    });
    
    if (resp.status === 'success') {
      this.setState({
        propertyID,
        landlordID: currentUser.user_id,
        property: resp.property,
        tenants: resp.rows,
      });
    }
  }

  render() {
    const { tenants, property, propertyID, landlordID } = this.state;
    const removedTenant = localStorage.landlordRemovedTenant;

    removedTenant && Toast(`${removedTenant} has been removed!`);
    localStorage.removeItem('landlordRemovedTenant');


    if (!tenants) return null;

    const hasTenants = tenants.length > 0;

    return (
      <Wrapper>
        <Link to="/landlord/properties?mode=tenant" style={{position: 'absolute', left: 20, top: 38}} className="goback">
          <FontAwesome icon="fas fa-chevron-left" size={30} color="#5a53a1" />
        </Link>
        <ViewHeader title="Tenants" subtitle={property.property_address} />
          <AddProperty>
            <Link to={{
              pathname:`/landlord/property/${propertyID}/tenants/add`,
              state: {
                landlordID,
                propertyID,
                'address': property.property_address
              }
            }}>Add a Tenant</Link>
          </AddProperty>
        {hasTenants && (
          <UsersListView>
            {tenants.map(row => (
              <UserCard context={this.props.context} propertyID={propertyID} landlordID={landlordID} address={property.property_address} key={row.user_id} user={row} />
            ))}
          </UsersListView>
        )}

        {!hasTenants && <NoneFoundView>No Tenants</NoneFoundView>}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  min-height: 100%;
  background: #fff;

  .subtitle {
    padding-top: 3px;
    font-weight: 600;
  }
`;

const AddProperty = styled.div`
  padding: 10px;
  border-top: solid 1px #f2f2f2;
  a {
    max-width: 480px;
    margin: 0 auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: #1b998b;
    color: white;
    font-size: 18px;
    border: none;
    height: 48px;
    border-radius: 6px;
    cursor: pointer;
    -webkit-transition: .2s all;
    transition: .2s all;
    box-sizing: border-box;
  }
`;

const UsersListView = styled.div`
  width: 100%;
  > div {
    margin-bottom: 1px;
  }
`;

const NoneFoundView = styled.div`
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: italic;
  font-size: 22px;
  opacity: .5;
  font-weight: 600;
`;

export default withGlobal(LandlordTenants);
