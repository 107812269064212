import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

function MonthYearCard(props) {
  const {
    item
  } = props;

  const amountInDollars = (amount) => {
    return `$${(amount / 100).toLocaleString(undefined, {minimumFractionDigits: 2})}`;
  };

  const percentagePaid = (amount, amountDue) => {
    let received = amountInDollars(amount);
    if ((amount / amountDue) < 1) {
      let stillDue = amountInDollars((amountDue - amount));
      return (
        <div className="paid-status">
          <div><p>{received} Received</p></div>
          <div className="still-due"><p>{stillDue} Still Due</p></div>
        </div>
      )
    } else {
      return (
        <div className="paid-status">
          <div><p style={{fontSize: 18, margin: '25px 0 0'}}>Full Amount Received!<br/><span style={{color: '#767676'}}>{received}</span></p></div>
        </div>
      )
    }
  };

  const month = item.name.split(' ')[0].toLowerCase();
  const year = item.name.split(' ')[1];

  const linkRoute = `/landlord/payment-history/${item.propertyID}/${month}/${year}`;

  return (
    <Container>
      <Link to={linkRoute}>
        <div className="payment-info">  
          <div className="month-year">
            {item.name}
          </div>
          <div className="status">
            {percentagePaid(item.amount, item.propertyRentAmount)}
          </div>
        </div>
      </Link>
    </Container>
  );
}

const Container = styled.div`
  box-sizing: border-box;
  margin: 0;
  border-top: solid 1px #f2f2f2;
  width: 100%;

  .payment-info {
    padding: 0 0 30px;
    border-bottom: solid 1px #f2f2f2;
  }

  .payment-info:last-of-type {
    border: none;
  }

  .status {
    padding: 0 30px;
    max-width: 300px;
    margin: 0 auto;
  }

  .month-year {
    letter-spacing: 0.3px;
    text-align: center;
    font-size: 17px;
    color: #444;
    margin-bottom: 20px;
    padding: 10px 30px;
    border-bottom: solid 1px #f2f2f2;
  }

  .paid-status div {
    margin-top: 10px;
    border-radius: 32px;
    line-height: 43px;
    text-align: center;
    padding: 0px 14px;
    font-weight: 600;
    font-size: 18px;
    color: #288e2c;
  }

  .paid-status .still-due {
    background-color: #e7e7e7;
    color: rgb(60, 60, 60);
  }

`;

export default MonthYearCard;
