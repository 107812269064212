import React, { Component } from "react";
import styled from "styled-components";
import queryString from "query-string";
import TextField from "@material-ui/core/TextField";
import { Link } from 'react-router-dom';
import { setPassword, checkActivated } from "../../services/AppService";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "../Button/Button";
import Toast from "../../shared/toast";

export default class SetPassword extends Component {
  state = {
    email: null,
    token: null,
    passwordA: "",
    passwordB: "",
    isComplete: false,
    isActivated: false
  };

  async componentWillMount() {
    const params = queryString.parse(this.props.location.search);
    const email = atob(params.email);
    const token = params.token;

    if (!email || !token) window.location = "/";

    const result = await checkActivated({ email });

    if (result.activated) {
      this.setState({
        isActivated: true
      })
    } else {
      this.setState({ email, token });
    }
  }

  listenForEnter(event) {
    if (event.which === 13 || event.keyCode === 13) {
      this.handleSubmit();
    }
  };

  handleSubmit = () => {
    const { email, token, passwordA, passwordB } = this.state;

    if (passwordA.length < 8) {
      Toast("Password must be at least 8 characters.");
      return;
    }

    if (passwordA !== passwordB) {
      Toast("Passwords do not match. Please double check and try again.");
      return;
    }

    this.setState({ isLoading: true });

    setPassword({ email, auth_token: token, password: passwordA }).then(
      result => {
        if (!result || result.status === "error") {
          return Toast("You've already created a password. Please return to the homepage and sign in.");
        }

        Toast("Successfully created password!");
        this.setState({
          isComplete: true
        });
      }
    );
  };

  render() {
    const { email, passwordA, passwordB, token, isComplete, isActivated } = this.state;
    if ((!email || !token) && !isActivated) return null;

    return (
      <OuterWrapper>
        <Wrapper>
          {isActivated && (
            <div>
              <h2 style={{fontSize: "24px", textAlign: "center", marginBottom: "15px"}}>Already Activated</h2>
              <p style={{textAlign: 'center', fontSize: 18}}>You've already activated your account.</p>
              <p style={{textAlign: 'center', fontSize: 18}}>If you want to sign in or are having trouble remembering your password click below to go to the sign in page where you will have the option to sign in or update the current password for your account. Thanks!</p>
              <Link style={{maxWidth: 140, margin: "20px auto 0"}} className="gotologin" to='/sign-in'>Continue</Link>
            </div>
          )}
          {isComplete && (
            <div>
              <h2 style={{fontSize: "24px", textAlign: "center", marginBottom: "15px"}}>Thank you!</h2>
              <Link className="gotologin" to='/sign-in'>Continue to Sign In</Link>
            </div>
          )}
          {(!isComplete && !isActivated) && (
            <Container>
              <h2 style={{fontSize: "24px", textAlign: "center", marginBottom: "15px"}}>Create Password</h2>
              <p style={{textAlign: "center"}}>Account email: {email}</p>
              <Row>
                <TextField
                  className="form_item"
                  label="Password"
                  type="password"
                  value={passwordA}
                  onChange={event =>
                    this.setState({ passwordA: event.target.value })
                  }
                  onKeyUp={(e) => { this.listenForEnter(e) }}
                />
              </Row>
              <Row>
                <TextField
                  className="form_item"
                  label="Confirm Password"
                  type="password"
                  value={passwordB}
                  onChange={event =>
                    this.setState({ passwordB: event.target.value })
                  }
                  onKeyUp={(e) => { this.listenForEnter(e) }}
                />
              </Row>
              <Row>
                <Button
                  stretch
                  label="Create My Password"
                  style={{ marginTop: 20 }}
                  onClick={this.handleSubmit}
                />
              </Row>
            </Container>
          )}
        </Wrapper>
      </OuterWrapper>
    );
  }
}
const Wrapper = styled.div`
  padding: 0 30px;
  width: 100%;
  max-width: 420px;
  margin: 0 auto;
  .form_item {
    width: 100%;
  }
  .gotologin {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: #1b998b;
    color: white;
    font-size: 18px;
    border: none;
    height: 48px;
    border-radius: 6px;
    cursor: pointer;
    -webkit-transition: .2s all;
    transition: .2s all;
    box-sizing: border-box;
    width: 100%;
    text-decoration: none;
  }
`;

const OuterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  padding-top: 100px;
`;
