import React, { Component } from 'react';
import { node, func, object, string, number, bool } from 'prop-types';
import styled from 'styled-components';

import FontAwesome from '../FontAwesome/FontAwesome';

export default class Button extends Component {
  static propTypes = {
    anti: bool,
    black: bool,
    cancel: bool,
    dev: bool,
    disabled: bool,
    icon: string,
    iconSize: number,
    label: node,
    labelLoading: node,
    loaderColor: string,
    loading: bool,
    mega: bool,
    onClick: func,
    stretch: bool,
    style: object,
  };

  static defaultProps = {
    onClick: () => {},
    style: {},
  };

  render() {
    const {
      anti,
      black,
      cancel,
      dev,
      disabled,
      icon,
      iconSize,
      label,
      labelLoading,
      loaderColor,
      loading,
      mega,
      onClick,
      stretch,
      style,
      toggleLabel
    } = this.props;

    let labelCheck = toggleLabel ? 0 : 2;

    return (
      <Wrapper
        style={style}
        disabled={disabled}
        onClick={disabled ? () => {} : onClick}
        iconOnly={!label}
        mega={mega}
        dev={dev}
        black={black}
        anti={anti}
        cancel={cancel}
        stretch={stretch}
      >
        {loading && (
          <FontAwesome
            spin
            icon={'fa fa-spinner'}
            gapRight={toggleLabel ? labelCheck : mega ? 10 : 2}
            size={iconSize}
            color={loaderColor || 'white'}
            style={{ paddingLeft: label ? 0 : 2 }}
          />
        )}

        {loading && labelLoading && <span style={{ fontStyle: 'italic' }}>{labelLoading}</span>}

        {!loading && icon && (
          <FontAwesome
            white
            icon={icon}
            gapRight={mega ? 10 : label ? 6 : 0}
            size={iconSize}
            style={{ paddingLeft: label ? 0 : 2 }}
          />
        )}

        {!loading && label}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1b998b;
  color: white;
  font-size: 18px;
  border: none;
  height: 48px;
  border-radius: 6px;
  cursor: pointer;
  transition: .2s all;
  box-sizing: border-box;

  @media all and (min-width: 768px) {
    &:hover {
      background-color: #560a5c;
    }
  }

  ${props =>
    props.disabled &&
    `
    background-color: #d2d2d2;
    opacity: 0.40;
    box-shadow: none;
    cursor: default;

    &:hover {
      background-color: #d2d2d2;
    }
  `}

  ${props =>
    props.mega &&
    `
    height: 68px;
    font-size: 21px;
  `}

  ${props =>
    props.iconOnly &&
    `
    width: 48px;
    border-radius: 24px;
  `}

  ${props =>
    props.black &&
    `
    background: rgb(80,80,80);
  `}

  ${props =>
    props.dev &&
    `
    background: rgb(60,60,60);
  `}

  ${props =>
    props.cancel &&
    `
    background: rgb(185, 185, 185);
  `}
  ${props =>
    props.anti &&
    `
    background: transparent;
    border: 2px solid white;
    box-shadow: none;
  `}

  ${props =>
    props.stretch &&
    `
    width: 100%;
  `}
`;
