import React from "react";
import styled from "styled-components";
import FontAwesome from "../FontAwesome/FontAwesome";
import { Link } from "react-router-dom";

function PropertyCard(props) {
  const { property, mode, history } = props;

  const actionType = mode === 'address' ? 'update' : 'tenants';
  const linkRoute = history ? `/landlord/payment-history/${property.property_id}` : `/landlord/property/${property.property_id}/${actionType}`;

  return (
    <Container>
      <Link to={{
          pathname: linkRoute,
          state: {
            rentAmount: property.rent_amount
          }
      }}>
        <div className="payment-card-icon">
          <FontAwesome icon="fa fa-home" size={20} color="#1b998b" />
        </div>   
        <div className="content">
          <div className="address uc">{property.property_address}</div>
        </div>
      </Link>
    </Container>
  );
}

const Container = styled.div`
  box-sizing: border-box;
  margin: 0;
  border-top: solid 1px #f2f2f2;

  .address {
    font-size: 18px;
  }

  .content {
    width: 100%;
    text-align: left;
    padding: 0 15px;
  }

  a {
    display: flex;
    padding: 0 25px;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 25px 30px;
    width: 100%;
    color: rgba(0,0,0,.7);
  }

  .payment-card-icon {
    border-radius: 50%;
    padding: 3.5px;
    border: solid 1px #e5e5e5;
  }
`;

export default PropertyCard;
