import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Header from '../Header/Header';
import StyledContainer from '../StyledContainer/StyledContainer';
import Container from 'react-bootstrap/Container';
import Logo from '../Logo/Logo';
import ForgotPasswordForm from '../Forms/ForgotPasswordForm';

function ForgotPassword() {
  return (
    <>
      <Header />
      <StyledContainer style={{ minHeight: "100vh" }}>
        <Content>
          <Container>
            <Logo style={{ marginBottom: 30 }} />
            <h1>Reset Password</h1>
            <p style={{fontSize: 20, marginBottom: 0}}>Enter your email address below</p>
            <View>
              <Form>
                <ForgotPasswordForm/>
              </Form>
            </View>
            <Link className="toHome" to="/">Return to Homepage</Link>
          </Container>
        </Content>
      </StyledContainer>
    </>
  );
}

const Content = styled.div`
  max-width: 640px;
  margin: 8vh 20px 0;
  text-align: center;
  width: 100%;

  @media all and (max-width: 767px) {
    margin-top: 80px;
  }

  img {
    max-width: 70px;
    margin: 0 auto;
  }

  .toHome {
    text-align: center;
    display: block;
    padding: 20px 0;
    color: #fff;
    font-size: 18px;
  }
`;

const View = styled.div`
  background: white;
  width: 100%;
  max-width: 360px;
  margin: 30px auto 0;
  color: black;
  border-radius: 5px;
  overflow: hidden;
`;

const Form = styled.div`
  padding: 20px;
`;

export default ForgotPassword;
