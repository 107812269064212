import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Footer = () => (
  <Container className={`home-footer ${window.location.pathname.indexOf('how-it-works') > 0 && 'expandContent'} ${window.location.pathname !== '/' && 'not-home'}`}>
    <Link to="/privacy-policy">
      <p className="lg">Privacy Policy</p>
    </Link>
    <Link to="/terms-of-use">
      <p className="lg">Terms Of Use</p>
    </Link>
    <p>© 2019 Little Payments Co.</p>
  </Container>
);

const Container = styled.div`
  height: 64px;
  padding: 0 25px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100vw;
  color: white;
  box-sizing: border-box;
  flex-wrap: wrap;
  background-color: #160d6f;

  &.not-home {
    a, p {
      color: #888;
      opacity: 1;
    }
  }

  @media all and (max-width: 1024px) {
    &.expandContent {
      padding: 30px 20px 93px;
    }
  }

  p {
    position: relative;
    top: 1px;
    margin: 0;
  }

  > p {
    margin: 0 0 0 auto;
  }

  a {
    margin-right: 30px;
  }

  @media all and (max-width: 767px) {
    a {
      display: block;
      width: 100%;
    }
  }
`;

export default Footer;
