import React from 'react'
import styled from 'styled-components'
import { APIContracts, APIControllers, Constants } from 'authorizenet'
import moment from 'moment'
import Button from '../Button/Button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import StyledContainer from '../StyledContainer/StyledContainer'
import { withUserHeader } from '../UserHeader/UserHeader'
import Toast from '../../shared/toast'
import FontAwesome from '../FontAwesome/FontAwesome'
import { submitPayment } from '../../services/AppService'

function AuthnetTenantPayment(props) {
	const { currentUser } = props.context
	const [amount, setAmount] = React.useState('0')
	const [authnetToken, setAuthnetToken] = React.useState(null)
	const [paymentInProgress, setPaymentInProgress] = React.useState(false)
	const [paymentState, setPaymentState] = React.useState('payment_incomplete')

	const onSelectNumber = number => {
		let newValue = `${amount.replace(/,/g, '')}${number}`

		if (newValue[0] === '0' && newValue.length > 1) {
			newValue = newValue.substring(1)
		}

		if (newValue.includes('.') && newValue.split('.')[1].length > 2) return

		const leadingDigits = parseInt(newValue.split('.')[0], 10)
		if (leadingDigits > 99999) return

		if (!leadingDigits) {
			newValue = `0${newValue}`
		}

		newValue = newValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

		setAmount(newValue)
	}

	const onSelectDecimal = () => {
		if (amount === '0') {
			setAmount('0.')
		} else if (!amount.includes('.')) {
			setAmount(`${amount}.`)
		}
	}

	const onSelectBackspace = () => {
		let newValue = `${amount.replace(/,/g, '')}`

		if (amount.length === 1) {
			setAmount('0')
			return
		}

		newValue = newValue.substr(0, newValue.length - 1)

		newValue = newValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

		setAmount(newValue)
	}

	const renderDecimal = () => (
		<Col xs={4}>
			<Btn onClick={() => onSelectDecimal()}>.</Btn>
		</Col>
	)

	const renderBackspace = () => (
		<Col xs={4}>
			<Btn onClick={() => onSelectBackspace()}>˂</Btn>
		</Col>
	)

	const renderNumber = number => (
		<Col xs={4}>
			<Btn onClick={() => onSelectNumber(number)}>{number}</Btn>
		</Col>
	)

	const saveSuccessfulTransaction = transactionResponse => {
		const transactionId = transactionResponse.getTransId()
		let card = transactionResponse.accountType
		card += ', '
		card += transactionResponse.accountNumber.replace(/X/g, '')

		const payload = {
			type: 'authnet',
			description: `authorize.net-charge-${moment().unix()}:${card}`,
			transaction_id: transactionId,
			amountInCents: currentUser.chargeAmountCents,
			tenantID: currentUser.user_id,
			landlordID: currentUser.landlord_user_id,
			landlordName: `${currentUser.landlord_first_name} ${currentUser.landlord_last_name}`,
			tenantName: `${currentUser.first_name} ${currentUser.last_name}`,
			propertyAddress: currentUser.property_address,
			propertyID: currentUser.property_id,
			propertyRentAmount: currentUser.rent_amount,
			charge_date: moment().unix(),
		}

		submitPayment({ payload }).then(result => {
			if (result && result.status === 'success') {
				setPaymentState('payment_success')
				Toast('Payment succeeded!')
				currentUser.transId =
					currentUser.accountNumber =
					currentUser.accountType =
					currentUser.cardNumber =
					currentUser.expiration =
					currentUser.cvc =
						undefined
			} else {
				Toast(result.error)
			}
			setPaymentInProgress(false)
		})
	}

	const validatePaymentInfo = () => {
		let chargeAmount = parseFloat(
			amount.replace(/,/g, '').toLocaleString()
		).toFixed(2)
		chargeAmount = parseFloat(chargeAmount)

		const chargeAmountCents = chargeAmount * 100

		if (chargeAmountCents < 100) {
			Toast('Your must enter at least $1')
			return
		}

		if (!currentUser.cardNumber) {
			Toast('Your card number is incomplete.')
			return
		}

		if (!currentUser.expiration) {
			Toast('Your card expiry is incomplete.')
			return
		}

		if (
			currentUser.expiration.indexOf('/') < 0 ||
			currentUser.expiration.length !== 5
		) {
			Toast('Card expiration format: MM/YY')
			return
		}

		if (!currentUser.cvc) {
			Toast('Your security code is incomplete.')
			return
		}

		setAuthnetToken(true)
	}

	const capturePayment = () => {
		const timeNow = moment()
		const lastLoginTime = moment.unix(currentUser.last_login)
		if (timeNow.diff(lastLoginTime, 'minutes') >= 30) {
			Toast('You need to log in again.')
			setTimeout(() => {
				props.context.logout()
			}, 1750)
			return
		}

		const cardNumber = currentUser.cardNumber.replace(/ /g, '')
		const cardExpiry = currentUser.expiration.replace('/', '')
		const cardCvc = currentUser.cvc.replace(/ /g, '')

		const merchantAuthenticationType =
			new APIContracts.MerchantAuthenticationType()
		merchantAuthenticationType.setName(process.env.REACT_APP_LOGIN_ID)
		merchantAuthenticationType.setTransactionKey(
			process.env.REACT_APP_TRANSACTION_KEY
		)

		const creditCard = new APIContracts.CreditCardType()
		creditCard.setCardNumber(cardNumber)
		creditCard.setExpirationDate(cardExpiry)
		creditCard.setCardCode(cardCvc)

		const paymentType = new APIContracts.PaymentType()
		paymentType.setCreditCard(creditCard)

		var billTo = new APIContracts.CustomerAddressType()
		billTo.setFirstName(currentUser.first_name)
		billTo.setLastName(currentUser.last_name)

		var addy = currentUser.property_address.split(',')
		billTo.setAddress(addy[0].trim())
		billTo.setCity(addy[1].trim())
		billTo.setState(addy[2].trim().substr(0, 2))
		billTo.setZip(addy[2].trim().substr(3, addy[2].length))
		billTo.setCountry('USA')
		billTo.setPhoneNumber(currentUser.phone)

		var customerProfileType = new APIContracts.CustomerProfileType()
		customerProfileType.setEmail(currentUser.email)

		const transactionRequestType = new APIContracts.TransactionRequestType()
		transactionRequestType.setTransactionType(
			APIContracts.TransactionTypeEnum.AUTHCAPTURETRANSACTION
		)
		transactionRequestType.setPayment(paymentType)
		transactionRequestType.setBillTo(billTo)
		transactionRequestType.setCustomer(customerProfileType)

		let chargeAmount = parseFloat(
			amount.replace(/,/g, '').toLocaleString()
		).toFixed(2)

		chargeAmount = parseFloat(chargeAmount) + 6.99
		currentUser.chargeAmountCents = chargeAmount * 100
		transactionRequestType.setAmount(chargeAmount)
		transactionRequestType.setCurrencyCode('USD')

		const createRequest = new APIContracts.CreateTransactionRequest()
		createRequest.setMerchantAuthentication(merchantAuthenticationType)
		createRequest.setTransactionRequest(transactionRequestType)

		const ctrl = new APIControllers.CreateTransactionController(
			createRequest.getJSON()
		)
		ctrl.setEnvironment(Constants.endpoint.production)

		ctrl.execute(function () {
			var apiResponse = ctrl.getResponse()
			var response = new APIContracts.CreateTransactionResponse(apiResponse)

			if (response != null) {
				if (
					response.getMessages().getResultCode() ===
					APIContracts.MessageTypeEnum.OK
				) {
					if (response.getTransactionResponse().getMessages() != null) {
						saveSuccessfulTransaction(response.getTransactionResponse())
					} else {
						setPaymentInProgress(false)
						if (response.getTransactionResponse().getErrors() != null) {
							Toast(`Error. Check your card information.`)
						}
					}
				} else {
					if (
						response.getTransactionResponse() != null &&
						response.getTransactionResponse().getErrors() != null
					) {
						Toast(`Error. Check your card information.`)
					} else {
						Toast(`Error. Check your card information.`)
					}
					setPaymentInProgress(false)
				}
			} else {
				setPaymentInProgress(false)
				Toast(`Please refresh and try again.`)
			}
		})
	}

	if (paymentState === 'payment_success') {
		return (
			<PaymentSuccessView>
				<FontAwesome
					icon="fas fa-check-circle"
					color="white"
					size={48}
					style={{ marginBottom: 20 }}
				/>
				<p
					style={{ fontWeight: 600, opacity: 1, fontSize: 32, marginBottom: 0 }}
				>
					$
					{(parseFloat(amount.replace(/,/g, '')) + 6.99).toLocaleString(
						undefined,
						{ minimumFractionDigits: 2 }
					)}{' '}
					Paid!
					<small
						style={{
							fontSize: 26,
							lineHeight: 'normal',
							display: 'block',
							opacity: '.85',
							textAlign: 'center',
						}}
					>
						Your payment was successful
					</small>
				</p>
				<div
					className="goBack"
					onClick={() => {
						setPaymentState('payment_incomplete')
						setAuthnetToken(false)
						setAmount(0)
					}}
				>
					All done!
				</div>
			</PaymentSuccessView>
		)
	}

	return (
		<Wrapper>
			<StyledContainer style={{ height: 210, width: '100%' }}>
				<div className="todays-payment-date">
					<span>{moment().format('MMMM Do, YYYY')}</span>
				</div>
				<div className="due_amount">
					${(currentUser.rent_amount / 100).toLocaleString()}
				</div>
				<div className="due_date">Total House Rent</div>
			</StyledContainer>
			<PaymentView>
				<Container>
					<Row className="justify-content-center">
						<div>
							<div className="dollar_symbol">$</div>
							<h1 className="input_amount">
								{!!authnetToken && amount
									? (
											parseFloat(amount.replace(/,/g, '')) + 6.99
									  ).toLocaleString(undefined, { minimumFractionDigits: 2 })
									: amount}
							</h1>
						</div>
					</Row>
					{!authnetToken && (
						<>
							<Row>
								{renderNumber(1)}
								{renderNumber(2)}
								{renderNumber(3)}
							</Row>
							<Row>
								{renderNumber(4)}
								{renderNumber(5)}
								{renderNumber(6)}
							</Row>
							<Row>
								{renderNumber(7)}
								{renderNumber(8)}
								{renderNumber(9)}
							</Row>
							<Row>
								{renderDecimal()}
								{renderNumber(0)}
								{renderBackspace()}
							</Row>
						</>
					)}

					{/* 1. Collect Card Info */}
					{!authnetToken && (
						<Row>
							<div className="payment-element-wrapper">
								<p className="cardInfo placeholder">
									Card Info <small>Powered by Authorize.net</small>
								</p>
								<div className="carddata">
									<div>
										<input
											onChange={e => {
												currentUser.cardNumber = e.currentTarget.value
											}}
											type="text"
											name="cardnumber"
											placeholder="Card number (spaces are ok)"
											maxLength="19"
										/>
									</div>
									<div>
										<input
											onChange={e => {
												currentUser.expiration = e.currentTarget.value
											}}
											type="text"
											name="expiration"
											placeholder="MM/YY"
											maxLength="5"
										/>
									</div>
									<div>
										<input
											onChange={e => {
												currentUser.cvc = e.currentTarget.value
											}}
											type="text"
											name="cvc"
											placeholder="CVC"
											maxLength="3"
										/>
									</div>
								</div>
								<Button
									label="Continue"
									stretch
									mega
									style={{ background: '#4036ad' }}
									onClick={validatePaymentInfo}
									loading={paymentInProgress}
									disabled={paymentInProgress}
								/>
							</div>
						</Row>
					)}

					{/* 2. Submit Payment */}
					{authnetToken && (
						<>
							<Row style={{ maxWidth: 315 }}>
								<p className="lptax">Includes a $6.99 Processing Fee</p>
								<Button
									label="Confirm Payment"
									stretch
									mega
									toggleLabel
									style={{
										borderTopRightRadius: 0,
										borderTopLeftRadius: 0,
										background: '#4036ad',
										padding: '0 25px',
										height: 52,
										fontSize: 18,
									}}
									onClick={capturePayment}
									loading={paymentInProgress}
									disabled={paymentInProgress}
								/>
							</Row>
							<Row>
								<div
									className="goBack"
									onClick={() => {
										setAuthnetToken(false)
										setAmount(amount)
									}}
								>
									Go back
								</div>
							</Row>
						</>
					)}
				</Container>
			</PaymentView>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: #160d70;
	border-bottom: 1000px solid 420px solid #160d70;
	color: white;
	position: relative;

	.cardInfo {
		font-size: 20px;
		margin-bottom: 5px;
	}

	.cardInfo small {
		display: block;
		opacity: 0.7;
		margin-top: -5px;
		font-size: 16px;
		text-align: center;
	}

	.payment-element-wrapper ::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		color: #767676;
	}
	.payment-element-wrapper ::-moz-placeholder {
		/* Firefox 19+ */
		color: #767676;
		opacity: 1;
	}
	.payment-element-wrapper :-ms-input-placeholder {
		/* IE 10+ */
		color: #767676;
	}
	.payment-element-wrapper :-moz-placeholder {
		/* Firefox 18- */
		color: #767676;
	}

	.payment-element-wrapper input {
		display: block;
		width: 100%;
		border: none;
		border-radius: 2px;
		padding: 14px 10px 12px;
		font-size: 20px;
		height: 51px;
		margin-bottom: 10px;
		color: #444;
		font-weight: 600;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	p.lptax {
		text-align: center;
		width: 100%;
		border: solid 1px rgba(255, 255, 255, 0.25);
		padding: 7px 0;
		margin-bottom: 0;
		border-bottom: none;
		opacity: 1;
		border-top-right-radius: 4px;
		border-top-left-radius: 4px;
	}

	.todays-payment-date {
		position: absolute;
		top: 40px;
		right: 25px;
		font-size: 16px;
	}

	.due_amount {
		padding-top: 85px;
		font-size: 32px;
		font-weight: 600;
		margin-bottom: 3px;
	}

	.due_date {
		background: #0e0949;
		padding: 5px 12px;
		font-size: 16px;
		letter-spacing: 0.3px;
		border-radius: 4px;
	}

	.input_amount {
		height: 100px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 72px;
	}

	.dollar_symbol {
		position: absolute;
		font-size: 27px;
		margin-left: -20px;
		margin-top: 10px;
	}

	.goBack {
		display: block;
		width: 100%;
		margin: 40px auto 0;
		padding: 10px 0;
		border: solid 1px #4f4d94;
		border: solid 1px rgba(255, 255, 255, 0.25);
		border-radius: 4px;
		max-width: 120px;
		cursor: pointer;
	}
`

const PaymentView = styled.div`
	text-align: center;
	padding: 20px;

	.payment-element-wrapper {
		width: 100%;
		margin-top: 30px;
	}
`

const Btn = styled.div`
	box-sizing: border-box;
	height: 90px;
	width: 90px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	font-size: 27px;
	font-weight: 600;
	cursor: pointer;
	transition: 0.2s all;
	max-width: 100%;

	&:hover {
		transform: scale(1.4);
	}
`

const PaymentSuccessView = styled.div`
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #160d70;
	color: white;
	padding: 30px;
	text-align: center;

	.goBack {
		display: block;
		width: 100%;
		margin: 40px auto 0;
		padding: 10px 0;
		border: solid 1px #4f4d94;
		border: solid 1px rgba(255, 255, 255, 0.25);
		border-radius: 4px;
		max-width: 120px;
		cursor: pointer;
	}
`

export default withUserHeader(AuthnetTenantPayment)
