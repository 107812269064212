import React from "react";
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Container from 'react-bootstrap/Container';
import StyledContainer from "../StyledContainer/StyledContainer";
import styled from "styled-components";

function PrivacyPolicy() {
  return (
    <>
      <Header />
      <StyledContainer>
        <Content>
          <Container className="titleContent" style={{ minHeight: 210 }}>
            <h1>Terms of Use</h1>
            <h3>The rules and guidelines for proper and safe usage</h3>
          </Container>
        </Content>
      </StyledContainer>
      <Content>
        <Container className="bodyContent">
          <p>Effective date: October 11, 2019</p>
          <p>Little Payments ("us", "we", or "our") operates the https://www.little-payments.com website (hereinafter referred to as the "Service").</p>
          <p>This page informs you of our policies regarding the use of our Service.</p>
          <h2>Opening an account</h2>
          <p>We offer two types of accounts.</p>
          <ol>
            <li>For property owners</li>
            <li>For property tenants/renters</li>
          </ol>
          <p><a href="./sign-up">Click here to open an account as a <strong>property owner</strong></a></p>
          <p>In order to open an account as a tenant/renter of a specific property the property owner must add you specifically within their account after they have signed up.</p>
          <h2>Adding/editing/removing a property</h2>
          <p>In order to add or edit a property you will need to be a registered property owner on our platform. At that point you may click <strong>Add or remove properties</strong> from your dashboard.</p>
          <h2>Adding/editing/removing a tenant/renter</h2>
          <p>In order to add, edit, or remove a tenant/renter you will need to be a registered property owner on our platform. At that point you may click <strong>Add or remove tenants</strong> from your dashboard.</p>
          <h2>Editing your personal information</h2>
          <p>In order to add or change your profile photo you will need to be a registered property owner or tenant/renter on our platform. At that point you may click the main image you first see on your dashboard or click <strong>Name, phone, email</strong> and click the image to change or update it.</p>
          <p>In order to edit your personal information you will need to be a registered property owner or tenant/renter on our platform. At that point you may click <strong>Name, phone, email</strong> from your dashboard.</p>
          <h2>Making a payment</h2>
          <p>In order to make a payment you will need to be a registered tenant/renter on our platform. At that point you may click <strong>Make a payment</strong> from your dashboard and enter the amount, your card details, and proceed with your payment.</p>
          <h2>Viewing your payment history</h2>
          <p>In order to view your payment history registered property owner or tenant/renter on our platform. At that point you may click <strong>Payment history</strong> from your dashboard and view all the information related to your payment history. Your receipts are availble from this screen also.</p>
          <h2>Contacting the property owner</h2>
          <p>In order to contact your property owner or your tenants/renters you will need to be a registered property owner or tenant/renter on our platform.</p>
          <ol>
            <li>For tenants/renters: click on <strong>Contact Landlord</strong> from your dashboard.</li>
            <li>For property owners: click on <strong>Add or remove tenants</strong>, select the property, and click on a contact option for the tenant/renter you wish to contact.</li>
          </ol>
          <h2>Closing your account</h2>
          <p>In order to close your account you will need to be a registered property owner or tenant/renter on our platform. At that point you may click on <strong>Name, phone, email</strong> and click <strong>Close my account</strong> while on the personal information screen.</p>
          <h2>Promoted Activities</h2>
          <ol>
            <li>Use of this Service to sign up and sign in at will in order to access the platform and its offerings.</li>
            <li>Use of this Service to add unlimited rental properties.</li>
            <li>Use of this Service to add unlimited tenants/renters to those properties.</li>
            <li>Use of this Service to receive unlimited amounts of U.S. currency funds.</li>
            <li>Use of this Service to pay unlimited amounts of U.S. currency funds to a registered landlord/lessor on our Service platform.</li>
            <li>Use of this Service to add/edit/delete rental properties.</li>
            <li>Use of this Service to add/edit/delete tenants/renters from those properties.</li>
            <li>Use of this Service to add/edit personal information that is collected (see <a href="./privacy-policy">privacy policy</a>).</li>
            <li>Use of our company name and all attributes to share or support the authorized use of this Service.</li>
            <li>Use of this Service to contact support with any and all questions related to our services as outlined in our <a href="./privacy-policy">privacy policy.</a></li>
          </ol>
          <h2>Prohibited Activities</h2>
          <p>You may not use the Little Payments service for activities that:</p>
          <ol>
            <li>Violate any law, statute, ordinance or regulation.</li>
            <li>Relate to transactions involving (a) narcotics, steroids, certain controlled substances or other products that present a risk to consumer safety, (b) drug paraphernalia, (c) cigarettes, (d) items that encourage, promote, facilitate or instruct others to engage in illegal activity, (e) stolen goods including digital and virtual goods, (f) the promotion of hate, violence, racial or other forms of intolerance that is discriminatory or the financial exploitation of a crime, (g) items that are considered obscene, (h) items that infringe or violate any copyright, trademark, right of publicity or privacy or any other proprietary right under the laws of any jurisdiction, (i) certain sexually oriented materials or services, (j) ammunition, firearms, or certain firearm parts or accessories, or (k) certain weapons or knives regulated under applicable law.</li>
            <li>Relate to transactions that (a) show the personal information of third parties in violation of applicable law, (b) support pyramid or ponzi schemes, matrix programs, other "get rich quick" schemes or certain multi-level marketing programs, (c) are associated with purchases of annuities or lottery contracts, lay-away systems, off-shore banking or transactions to finance or refinance debts funded by a credit card, (d) are for the sale of certain items before the seller has control or possession of the item, (e) are by payment processors to collect payments on behalf of merchants, (f) are associated with the sale of traveler's checks or money orders, (h)involve currency exchanges or check cashing businesses, (i) involve certain credit repair, debt settlement services, credit transactions or insurance activities, or (k) involve offering or receiving payments for the purpose of bribery or corruption.</li>
            <li>Involve the sales of products or services identified by government agencies to have a high likelihood of being fraudulent.</li>
          </ol>      
          <h2>Contact Us</h2>
          <p>If you have any questions about our Terms of Use Policy, please contact us:</p>
          <ul>
            <li>By email: <a href="mailto:support@little-payments.com">support@little-payments.com</a></li>
          </ul>
        </Container>
      </Content>
      <Footer />
    </>
  );
}

const Content = styled.div`
  max-width: 700px;
  margin: 64px auto 0;

  .titleContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .titleContent h1 {
    font-size: 42px;
    margin-bottom: 15px;
  }

  .titleContent h3 {
    text-align: center;
  }

  h3 {
    font-size: 28px;
    line-height: normal;
  }

  h4 {
    font-size: 24px;
    line-height: normal;
  }

  li {
    margin-bottom: 15px;
  }

  .bodyContent h2 {
    padding: 15px 0 0;
  }

  .bodyContent p,
  .bodyContent li {
    opacity: .9;
  }

  .bodyContent a {
    color: #0051a7;
  }

  .bodyContent {
    padding: 0 30px 50px;
  }

  .bodyContent h3,
  .bodyContent h4 {
    opacity: 1;
    padding: 15px 0;
  }

  @media all and (min-width: 1025px) {
    .titleContent {
      padding: 50px 0 20px;
    }
  }

  @media all and (max-width: 480px) and (min-width: 351px) {
    .titleContent h1 {
      font-size: 34px;
    }

    h3 {
      font-size: 24px;
    }

    h4 {
      font-size: 20px;
    }
  }

  @media all and (max-width: 350px) {
    .titleContent h1 {
      font-size: 29px;
    }

    h3 {
      font-size: 20px;
    }

    h4 {
      font-size: 17px;
    }
  }
`;

export default PrivacyPolicy;
