import React from "react";
import { Link } from 'react-router-dom';
import FontAwesome from "../FontAwesome/FontAwesome";
import styled from "styled-components";
import ViewHeader from "../ViewHeader/ViewHeader";
import SimpleImage from "../SimpleImage/SimpleImage";


function TenantContactLandlord(props) {
  const { currentUser } = props.context;
  const photoURL = currentUser.landlord_photo || false;
  const landlord = {
    user_id: currentUser.landlord_user_id,
    full_name: `${currentUser.landlord_first_name} ${currentUser.landlord_last_name}`,
    email: currentUser.landlord_email,
    phone: currentUser.landlord_phone
  };

  let phone = landlord.phone;
  phone = phone.replace(/-/g, '').replace('(','').replace(')','');

  return (
    <Wrapper>
      <Link to="/tenant/dashboard" style={{position: 'absolute', left: 20, top: 38}} className="goback">
        <FontAwesome icon="fas fa-chevron-left" size={30} color="#5a53a1" />
      </Link>
      <ViewHeader title="Contact Landlord" />
      <Container>
        {photoURL && (
          <SimpleImage
            context={props.context}
            circle
            framed
            src={photoURL}
            style={{ opacity: 0.85, width: 76, height: 76, margin: "0 auto 10px" }}
          />
        )}
        <div className="xl heavy">{landlord.full_name}</div>
        <div className="email"><a href={`mailto:${landlord.email}`}>Email</a></div>
        <div className="phone"><a href={`tel:+1${phone}`}>Call</a></div>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
`;

const Container = styled.div`
  width: 100%;
  padding: 25px;
  text-align: center;
  border-top: solid 1px #f2f2f2;

  > div {
    margin-bottom: 5px;
  }

  a {
    display: block;
    line-height: 1.7;
    padding: 4px 16px 3px;
    margin: 10px auto 0;
    max-width: 150px;
    font-size: 16px;
    background-color: #f7f7f7;
    border-radius: 4px;
    font-weight: 600;
    border: solid 1px #d5d5d5;
    color: #00685c;
  }
`;

export default TenantContactLandlord;
