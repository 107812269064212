import React, { Fragment, Component } from 'react';
import styled from 'styled-components';
import { AppContext } from '../../providers/AppProvider';

class Global extends Component {
  render() {
    return (
      <Fragment>
        <Content>{this.props.children}</Content>
      </Fragment>
    );
  }
}

export function withGlobal(Component, options) {
  return function WrapperComponent(props) {
    return (
      <AppContext.Consumer>
        {context => (
          <Global context={context} options={options}>
            <Component {...props} context={context} />
          </Global>
        )}
      </AppContext.Consumer>
    );
  };
}

const Content = styled.div`
  min-height: 100%;
`;
