import { toast, style } from 'react-toastify';

const Toast = message => {
  if (!message) return;
  toast.success(message, {
    closeButton: false,
    position: toast.POSITION.BOTTOM_CENTER,
    autoClose: 3000,
    style: style({
      colorSuccess: '#f2f2f2',
    }),
    className: {
      borderRadius: 0,
      boxShadow: '4px 4px 0px rgba(0,0,0,0.15)',
      fontSize: 18,
      fontWeight: 500,
      padding: '0px 10px',
      textAlign: 'center',
      color: '#444',
      minHeight: '75px',
      margin: 0
    },
  });
};

export default Toast;
