import React from "react";
import styled from "styled-components";
import Container from 'react-bootstrap/Container';
import StyledContainer from "../StyledContainer/StyledContainer";

export default function landlordStripeAccountCreated() {
  return (
    <>
      <StyledContainer style={{ background: '#f7f7f7', minHeight: "100vh" }}>
        <Content>
          <Container>
            <h2 style={{ fontSize: 36, marginTop: 20 }}>Oops...</h2>
            <View>
              <p style={{fontSize: 22}}>Something isn't right. Return to the <strong style={{fontWeight:600}}>first email</strong> you received from us, and complete <strong style={{fontWeight:600}}>step 1</strong> again.</p>
            </View>
            <View style={{marginTop: 50, borderTop: 'solid 1px rgba(255,255,255,.25)'}}>
              <p>If you've already tried this twice and still are getting this error please email us right away:<br/><a href="mailto:support@little-payments.com">support@little-payments.com</a></p>
            </View>
          </Container>
        </Content>
      </StyledContainer>
    </>
  );
}

const Content = styled.div`
  max-width: 640px;
  margin-top: 100px;
  text-align: center;
  width: 100%;

  * {
    color: rgba(0,0,0,0.7);
  }

  a {
    color: #015c52;
  }

  p {
    margin: 20px 0;
  }

  @media all and (max-width: 767px) {
    margin-top: 80px;
  }

  img {
    max-width: 70px;
    margin: 0 auto;
  }
`;

const View = styled.div`
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  color: white;
  text-align: center;
  overflow: hidden;
`;