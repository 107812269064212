import React, { Component } from "react";
import axios from 'axios';
import _ from 'lodash';
import queryString from "query-string";
import { saveLandlordStripeID } from "../../services/AppService";
import styled from "styled-components";
import Container from 'react-bootstrap/Container';
import StyledContainer from "../StyledContainer/StyledContainer";

export default class LandlordOAuth extends Component {
  state = {
    oAuthState: "",
    oAuthCode: ""
  };

  async componentWillMount() {
    this.status = "not sure";
    const params = queryString.parse(this.props.location.search);
    const oAuthState = atob(params.state).replace('lp-state-Oauth','');
    const oAuthCode = params.code;
    const stripeTokenURI = 'https://www.little-payments.com/lp-stripe-api/oAuth.php' +
    `?client_secret=${process.env.REACT_APP_STRIPE_CLIENT_SECRET}` +
    `&code=${oAuthCode}` +
    `&grant_type=authorization_code`;

    if (oAuthState && oAuthCode) {
      const authorization = await axios.get(stripeTokenURI);

      if (authorization.data.stripe_user_id) {
        localStorage.setItem('oAuthState', oAuthState);
        localStorage.setItem('stripeAcctID', authorization.data.stripe_user_id);
      }
    }

    this.setState({oAuthState, oAuthCode});
  }

  async saveIDResponse() {
    const payload = {
      email: localStorage.oAuthState,
      stripe_user_id: localStorage.stripeAcctID
    }

    const saveStripeIDResponse = await saveLandlordStripeID(payload);

    localStorage.removeItem('oAuthState');
    localStorage.removeItem('stripeAcctID');

    if (_.trim(saveStripeIDResponse.status) === 'success') {
      window.location = "/landlord/stripe-account/created";
    } else {
      window.location = "/landlord/stripe-account/error";
    }
  }

  render() {
    const { oAuthState, oAuthCode } = this.state;

    if (oAuthState || oAuthCode) {
      this.saveIDResponse();
    }

    return (
      <>
      <StyledContainer style={{ background: '#f7f7f7', minHeight: "100vh" }}>
        <Content>
          <Container>
            <View className="loading">
              <img alt="loading" src="/images/loading-dark.svg"/>
            </View>
            </Container>
        </Content>
      </StyledContainer>
      </>
    );
  }
}

const Content = styled.div`
  max-width: 640px;
  text-align: center;
  width: 100%;
  img {
    max-width: 70px;
    margin: 0 auto;
  }
`;

const View = styled.div`
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  color: rgba(0,0,0,.8);
  text-align: center;
  overflow: hidden;
`;
