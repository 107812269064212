import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import StyledContainer from "../StyledContainer/StyledContainer";
import Logo from "../Logo/Logo";
import Header from '../Header/Header';

function PasswordResetSuccess() {
  return (
    <>
      <Header />
      <StyledContainer style={{ minHeight: "100vh" }}>
        <Content>
          <Container>
            <Logo style={{ marginBottom: 30 }}/>
            <h2 style={{ fontWeight: 600, fontSize: 28, margin: "20px 0"}}>Check your email & junk folders!</h2>
            <View>
              <p style={{fontSize: 22}}>Please check your SPAM/Junk folder also. Click the link in your email to reset your password and re-gain access to your account.</p>
            </View>
            <BackView>
              <Link style={{fontSize: 18}} to="/">Return Home</Link>
            </BackView>
          </Container>
        </Content>
      </StyledContainer>
    </>
  );
}

const Content = styled.div`
  max-width: 640px;
  margin: 8vh 20px 0;
  text-align: center;
  width: 100%;

  @media all and (max-width: 767px) {
    margin-top: 80px;
  }

  img {
    max-width: 70px;
    margin: 0 auto;
  }
`;

const View = styled.div`
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  color: white;
  text-align: center;
  border-radius: 5px;
  overflow: hidden;
`;

const BackView = styled.div`
  text-align: center;
  padding: 30px 20px;
`;

export default PasswordResetSuccess;
