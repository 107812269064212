import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Header = () => (
  <Container className="home-header">
    <Link to="/">
      <p className="lg">Little Payments</p>
    </Link>
    {window.location.pathname.indexOf('how-it-works') > 0 && (
      <Link className="cta stickyFooter" to="/sign-up">
        <p className="lg">Sign Up</p>
      </Link>
    )}
  </Container>
);

// {window.location.pathname === "/" && (
//   <Link className="cta" to="/how-it-works">
//     <p className="lg">How it Works</p>
//   </Link>
// )}

const Container = styled.div`
  height: 64px;
  padding: 0 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  color: white;
  box-sizing: border-box;
  border-bottom: solid 1px rgba(255,255,255,.11);
  
  .cta {
    padding: 5px 15px;
    border: solid 1px rgba(255,255,255,.5);
    background-color: #fff;
    border-radius: 3px;
  }

  .cta p {
    color: #342372;
    font-weight: 600;
    opacity: 1;
  }

  .stickyFooter {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 99;
    border-top: solid 1px #d5d5d5;
  }

  .stickyFooter p {
    background-color: #1b998b;
    color: #fff;
    padding: 12px 0;
    border-radius: 4px;
    font-size: 20px;
    max-width: 320px;
    margin: 0 auto;
  }

  p {
    position: relative;
    top: 1px;
    margin: 0;
  }

  @media all and (min-width: 1025px) {
    .stickyFooter {
      display: none;
    }
  }
`;

export default Header;
