import React from 'react';
import styled from 'styled-components';

const ViewHeader = props => {
  const { title, subtitle } = props;
  return (
    <Container>
      <p className="xl title">{title || 'View Title'}</p>
      {subtitle && (
        <div className="subtitle_container">
          <p className="sm subtitle">{subtitle}</p>
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  height: 110px;
  width: 100%;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .title {
    margin-bottom: -4px;
    color: #5953a1;
    opacity: 1;
    font-weight: 600;
  }

  .subtitle {
    opacity: 0.8;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .sm.subtitle {
    margin: 0;
  }

  @media (max-width: 720px) {
    .subtitle_container {
      max-width: 320px;
    }
  }

  @media (max-width: 575px) {
    align-items: flex-end;
    text-align: right;
    .subtitle_container {
      max-width: 200px;
    }
  }
`;

export default ViewHeader;
