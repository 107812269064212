import React from "react";
import styled from "styled-components";
import StyledContainer from "../StyledContainer/StyledContainer";
import SimpleImage from "../SimpleImage/SimpleImage";
import Logo from "../Logo/Logo";
import FontAwesome from "../FontAwesome/FontAwesome";
import { Link } from "react-router-dom";

function TenantDashboard(props) {
  const { currentUser } = props.context;
  const photoURL = currentUser.photo || "/images/default_user.jpg";

  const DashSection = ({ title, content, url }) => (
    <Link to={url}>
      <Section className='dash-section' style={{ marginBottom: 16 }}>
        <h3>{title}</h3>
        <span>{content}</span>
      </Section>
    </Link>
  );

  return (
    <>
      <Link to="/tenant/payment">
        <ButtonContainer>
          <FontAwesome
            icon="fas fa-arrow-circle-right"
            size={32}
            color="white"
          />
        </ButtonContainer>
      </Link>
      <Container>
        <StyledContainer style={{ height: 210 }}>
          <SimpleImage
            context={props.context}
            upload
            circle
            framed
            src={photoURL}
            style={{ opacity: 0.85, width: 76, height: 76, marginBottom: 10 }}
          />
          <div className="xl label_name">
            {currentUser.first_name} {currentUser.last_name}
          </div>
          <div className="label_title">{currentUser.email}</div>
        </StyledContainer>
        <Section transparent style={{padding: "30px 20px"}}>
          <h3>Address</h3>
          <div className='tenant-address'>
            <span className="uc">{currentUser.property_address}</span>
          </div>
        </Section>

        {DashSection({
          title: "Simple & Secure",
          content: "Make a payment",
          url: "/tenant/payment"
        })}

        {DashSection({
          title: "Payment History",
          content: "View all transactions",
          url: "/tenant/payment-history"
        })}

        {DashSection({
          title: "Personal Information",
          content: "Name, phone, email",
          url: "/tenant/info"
        })}

        <Section transparent>
          <Footer>
            <Logo flat size={48} style={{ marginBottom: 20 }} />
            <p>
              <Link to="/tenant/contact-landlord">Contact Landlord</Link>
            </p>
            <p>
              <Link to="/tenant/support">Support & Help</Link>
            </p>
            <div onClick={() => props.context.logout()}>
              <p>Sign Out</p>
            </div>
          </Footer>
        </Section>
      </Container>
    </>
  );
}

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;

  p {
    font-size: 15px;
  }

  span {
    font-size: 18px;
  }

  .label_name {
    padding: 5px 0 0;
    font-size: 20px;
  }

  .label_title {
    font-size: 16px;
  }
`;

const Section = styled.div`
  text-align: center;
  padding: 20px;
  background: white;
  color: rgba(0, 0, 0, 0.7);

  .nav {
    position: absolute;
    right: 0;
    margin-top: -32px;
    margin-right: 20px;
  }

  .tenant-address {
    margin: 0 auto;
    max-width: 255px;
  }

  ${props =>
    props.transparent &&
    `
    background: none;
  `}
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  a,
  p {
    color: rgba(0, 0, 0, 0.7);
    opacity: 1;
    cursor: pointer
    font-size: 16px;
  }
`;

const ButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 64px;
  width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s all;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`;

export default TenantDashboard;
