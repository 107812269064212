import axios from 'axios'

const baseURL =
	process.env.REACT_APP_API_PATH ||
	'https://little-payments-api.herokuapp.com/api'
const client = axios.create({ baseURL })

// Request Wrapper with default success/error actions
const request = function (options) {
	const onSuccess = function (response) {
		return response.data
	}

	const onError = function (error) {
		if (process.env.REACT_APP_ENVIRONMENT !== 'local') return

		// Request was made but server responded with something other than 2xx
		if (error.response) {
			console.error('Status:', error.response.status)
			console.error('Data:', error.response.data)
			console.error('Headers:', error.response.headers)
		} else {
			console.error('Error Message:', error.message)
		}

		return Promise.reject(error.response || error.message)
	}

	return client(options).then(onSuccess).catch(onError)
}

export default request
