import React, { Component } from "react";
import { Link } from 'react-router-dom';
import FontAwesome from "../FontAwesome/FontAwesome";
import styled from "styled-components";
import queryString from "query-string";
import PropertyCard from "../PropertyCard/PropertyCard";
import ViewHeader from "../ViewHeader/ViewHeader";
import {
  propertiesForLandlordID
} from "../../services/AppService";

class LandlordPaymentHistoryProperties extends Component {
  state = {
    mode: "address",
    properties: null
  };

  async componentWillMount() {
    const params = queryString.parse(this.props.location.search);
    this.reloadProperties(params.mode);
  }

  async reloadProperties(mode) {
    const { currentUser } = this.props.context;
    const resp = await propertiesForLandlordID(currentUser.user_id);
    if (resp.status === "success") {
      this.setState({
        mode,
        properties: resp.rows
      });
    }
  }

  render() {
    const { properties, mode } = this.state;

    if (!properties) return null;

    return (
      <Wrapper>
        <Link to="/landlord/dashboard" style={{position: 'absolute', left: 20, top: 38}} className="goback">
          <FontAwesome icon="fas fa-chevron-left" size={30} color="#5a53a1" />
        </Link>
        <ViewHeader title="Payment History" />
        <p style={{margin: "-5px auto 20px", maxWidth: 200}}>Select a property to view received payments</p>
        <PropertiesListView>
          {properties.map(row => (
            <PropertyCard history key={row.property_id} property={row} mode={mode} />
          ))}
        </PropertiesListView>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  min-height: 100%;
  background: #fff;
  .form_item {
    width: 100%;
  }
  .form_item input {
    text-transform: capitalize !important;
  }
  #state {
    text-transform: uppercase !important;
  }
  p {
    text-align: center;
  }
`;

const PropertiesListView = styled.div`
  width: 100%;
  > div {
    margin-bottom: 1px;
  }
`;

export default LandlordPaymentHistoryProperties; 