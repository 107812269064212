import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import styled from 'styled-components'
import Button from '../Button/Button'
import Logo from '../Logo/Logo'
import StyledContainer from '../StyledContainer/StyledContainer'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Link } from 'react-router-dom'

function Home() {
	localStorage.clear()

	return (
		<>
			<Header />
			<StyledContainer style={{ minHeight: 'calc(100vh - 64px)' }}>
				<Content>
					<Container>
						<Row className="justify-content-center logo">
							<Logo />
						</Row>
						<Row className="justify-content-center header">
							<h1>Collecting rent. Made easy.</h1>
							<p className="xl">
								Accept secure online payments from your tenants and simplify tax
								time with complimentary monthly and yearly statements.
							</p>
						</Row>
						<Row style={{ maxWidth: 550, margin: '0 auto' }}>
							<Col xs={12}>
								<Link to="/sign-in">
									<Button
										label="Tenant Sign In"
										mega
										style={{ marginBottom: 20 }}
									/>
								</Link>
							</Col>
							<Col xs={12} sm={6}>
								<Link to="/sign-in">
									<Button
										label="Landlord Sign In"
										mega
										anti
										style={{ marginBottom: 20 }}
									/>
								</Link>
							</Col>
							<Col xs={12} sm={6}>
								<Link to="/sign-up">
									<Button
										label="Landlord Sign Up"
										mega
										anti
										style={{ marginBottom: 20 }}
									/>
								</Link>
							</Col>
						</Row>
					</Container>
				</Content>
			</StyledContainer>
			<Footer />
		</>
	)
}

const Content = styled.div`
	max-width: 700px;
	margin: 8vh 20px 0;
	text-align: center;

	@media all and (max-width: 767px) {
		margin-top: 80px;
	}

	.logo {
		max-width: 70px;
		margin: 0 auto 30px;
	}

	.header {
		margin-bottom: 20px;
	}

	.signup {
		font-weight: 600;
	}
`

export default Home
