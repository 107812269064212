import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Container from 'react-bootstrap/Container'
import StyledContainer from '../StyledContainer/StyledContainer'
import Logo from '../Logo/Logo'
import RegisterLandlordForm from '../Forms/RegisterLandlordForm'
import Header from '../Header/Header'

function SignUp() {
	return (
		<>
			<Header />
			<StyledContainer style={{ minHeight: '100vh' }}>
				<Content>
					<Container style={{ padding: 0 }}>
						<Logo style={{ marginBottom: 30 }} />
						<h1>Sign Up</h1>
						<p
							style={{
								fontSize: 17,
								fontWeight: 600,
								maxWidth: 400,
								margin: '0 auto',
							}}
						>
							Landlords sign up free! If you're a tenant, don't sign up here.
							Please talk to your landlord about adding you as a tenant; or
							check your email inbox and junk folders for an email from us.
						</p>
						<View>
							<Form>
								<RegisterLandlordForm />
							</Form>
							<p className="termsAndPrivacy">
								By clicking Continue, you agree to our{' '}
								<Link to="/terms-of-use">Terms</Link> and{' '}
								<Link to="/privacy-policy">Privacy Policy</Link>.
							</p>
						</View>
						<Link className="toHome" to="/">
							Return to Homepage
						</Link>
					</Container>
				</Content>
			</StyledContainer>
		</>
	)
}

const Content = styled.div`
	max-width: 640px;
	margin: 8vh 20px 0;
	text-align: center;
	width: 100%;

	@media all and (max-width: 767px) {
		margin-top: 80px;
	}

	img {
		max-width: 70px;
		margin: 0 auto;
	}

	.toHome {
		text-align: center;
		display: block;
		padding: 20px 0;
		color: #fff;
		font-size: 18px;
	}

	.termsAndPrivacy {
		padding: 0 0 20px;
		font-size: 13px;
		color: #888;
		opacity: 1;
		text-align: center;
		max-width: 220px;
		margin: 0 auto;
	}

	.termsAndPrivacy a {
		color: #0055b0;
	}
`

const View = styled.div`
	background: white;
	width: 100%;
	max-width: 360px;
	margin: 30px auto 0;
	color: black;
	border-radius: 5px;
	overflow: hidden;
`

const Form = styled.div`
	padding: 20px;
`

export default SignUp
