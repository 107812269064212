import React from "react";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "../Button/Button";
import InputMask from "react-input-mask";
import { withRouter } from "react-router-dom";
import { createLandlord } from "../../services/AppService";
import Toast from "../../shared/toast";

function RegisterLandlordForm(props) {
  const [values, setValues] = React.useState({
    first_name: "",
    last_name: "",
    email: "",
    confirm_email: "",
    phone: ""
  });

  const listenForEnter = (event) => {
    if (event.which === 13 || event.keyCode === 13) {
      handleSubmit();
    }
  };

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      return Toast(`Please complete all form fields.`);
    }

    const obj = Object.assign({}, values);

    if (obj.email !== obj.confirm_email) {
      return Toast(`Emails don't match! Please check.`);
    }

    const payload = {
      ...values,
      email: values.email.toLowerCase(),
      base64Email: btoa(obj.email.toLowerCase()),
      oAuth: btoa('lp-state-Oauth' + obj.email.toLowerCase())
    };
    createLandlord({ payload }).then(result => {
      if (!result || result.status === "error") {
        const errorMessage =
          result.error || "Unable to complete registration at this time.";
        return Toast(errorMessage);
      }

      if (result.status === "success") {
        localStorage.setItem('email', btoa(payload.email));
        localStorage.setItem('token', result.authToken);
        localStorage.setItem('oAuthState', btoa('lp-state-Oauth' + payload.email));
        props.history.push(`/landlord/registered`);
      }
    });
  };
  const validateForm = () => {
    let isPass = true;
    Object.values(values).forEach(item => {
      if (!item.replace(/\s/g, "")) isPass = false;
    });
    return isPass;
  };

  return (
    <Wrapper>
      <Container>
        <Row>
          <TextField
            className="form_item"
            label="First Name"
            value={values.first_name}
            onChange={handleChange("first_name")}
            onKeyUp={(e) => { listenForEnter(e) }}
          />
        </Row>
        <Row>
          <TextField
            className="form_item"
            label="Last Name"
            value={values.last_name}
            onChange={handleChange("last_name")}
            onKeyUp={(e) => { listenForEnter(e) }}
          />
        </Row>
        <Row>
          <TextField
            className="form_item"
            label="Email"
            type="email"
            value={values.email}
            onChange={handleChange("email")}
            onKeyUp={(e) => { listenForEnter(e) }}
          />
        </Row>
        <Row>
          <TextField
            className="form_item"
            label="Confirm Email"
            type="email"
            value={values.confirm_email}
            onChange={handleChange("confirm_email")}
            onKeyUp={(e) => { listenForEnter(e) }}
          />
        </Row>
        <Row>
          <InputMask
            mask="(999) 999-9999"
            value={values.phone}
            onChange={handleChange("phone")}     
          >
            {() => (
              <TextField
                id="phone"
                className="form_item"
                label="phone"
                name="phone"
                type="text"
                onKeyUp={(e) => { listenForEnter(e) }}
              />
            )}
          </InputMask>
        </Row>
        <Row>
          <Button
            label="Continue"
            style={{ width: "100%", marginTop: 20 }}
            onClick={handleSubmit}
          />
        </Row>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .form_item {
    width: 100%;
  }
`;

export default withRouter(RegisterLandlordForm);
