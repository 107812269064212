import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import FontAwesome from '../FontAwesome/FontAwesome'
import { path } from 'ramda'
import moment from 'moment'
import styled from 'styled-components'
import ViewHeader from '../ViewHeader/ViewHeader'
import PaymentCard from '../PaymentCard/PaymentCard'
import { fetchPayments } from '../../services/AppService'

class LandlordPaymentHistory extends Component {
	state = {
		month: null,
		year: null,
		propertyID: null,
		payments: null,
	}

	async componentWillMount() {
		const propertyID = path(['match', 'params', 'propertyID'], this.props)
		const month = path(['match', 'params', 'month'], this.props)
		const year = path(['match', 'params', 'year'], this.props)
		const { auth_token } = this.props.context.currentUser
		const resp = await fetchPayments({ auth_token })

		if (resp.status === 'success') {
			this.setState({
				month,
				year,
				propertyID,
				payments: resp.rows,
			})
		}
	}

	handlePaymentDates() {
		const { payments, propertyID, month, year } = this.state
		const paymentsLength = payments.length
		let x, monthKey, yearKey, curDay

		let paymentsArr = []
		for (x = 0; x < paymentsLength; x++) {
			const dateObj = moment.unix(payments[x].date)
			if (parseInt(propertyID) === payments[x].property_id) {
				monthKey = dateObj.format('MMMM').toLowerCase()
				yearKey = dateObj.get('year')
				curDay = parseInt(dateObj.format('D'))
				if (
					curDay > 10 &&
					monthKey !== 'december' &&
					month === dateObj.add(1, 'M').format('MMMM').toLowerCase() &&
					yearKey === parseInt(year)
				) {
					paymentsArr.push(payments[x])
				} else if (
					monthKey === month &&
					yearKey === parseInt(year) &&
					curDay <= 10
				) {
					paymentsArr.push(payments[x])
				}
				if (
					month === 'january' &&
					monthKey === 'december' &&
					yearKey === parseInt(year) - 1 &&
					curDay > 10
				) {
					paymentsArr.push(payments[x])
				}
			}
		}

		const renderPayments = paymentsArr.map(payment => {
			return (
				<PaymentCard
					key={payment.description}
					mode="landlord"
					payment={payment}
				/>
			)
		})

		return renderPayments
	}

	render() {
		const { payments, propertyID } = this.state

		if (!payments) return null

		return (
			<Wrapper>
				<Link
					to={`/landlord/payment-history/${propertyID}`}
					style={{ position: 'absolute', left: 20, top: 38 }}
					className="goback"
				>
					<FontAwesome icon="fas fa-chevron-left" size={30} color="#5a53a1" />
				</Link>
				<ViewHeader title="Payments Recieved" />
				<p
					style={{
						maxWidth: 300,
						margin: '-5px auto 25px',
						textAlign: 'center',
					}}
				>
					After August 2020, Each reciept shows the amount you received from the
					tenant.
				</p>
				{this.handlePaymentDates()}
			</Wrapper>
		)
	}
}

const Wrapper = styled.div`
	min-height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: #fff;
`

export default LandlordPaymentHistory
