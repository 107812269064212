import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import StyledContainer from "../StyledContainer/StyledContainer";
import styled from 'styled-components';
import { path } from "ramda";
import { Link, withRouter } from 'react-router-dom';
import Logo from "../Logo/Logo";

class PaymentReceipt extends Component {
  state = {
    mode: "address",
    properties: null
  };

  componentWillMount() {
    this.receiptID = path(["match", "params", "receiptID"], this.props);
  }

  render() {
    const { mode, amount, contact, back, date, description, from, status, to } = this.props.location.state;

    if (!this.receiptID) return null;

    return (
      <StyledContainer style={{ background: '#f7f7f7', minHeight: "100vh" }}>
        <Content>
          <Container>
            <div className="receipt">
              <Logo flat style={{ marginBottom: 20, maxWidth: '50px', marginLeft:'auto', marginRight:'auto' }}/>
              <h2>Payment Receipt</h2>
              <ul>
                <li><span>Status:</span> {status}</li>
                <li><span>Amount {mode === 'landlord' ? 'Received' : 'Paid'}:</span> {amount}</li>
                <li><span>Date {mode === 'landlord' ? 'Received' : 'Paid'}:</span> {date}</li>
                <li><span>Paid By:</span> {from}</li>
                <li><span>Paid To:</span> {to}</li>
                <li><span>Payment Method:</span> {description}</li>
                <li><span>TransactionID:</span> {path(["match", "params", "receiptID"], this.props)}</li>
                {mode !== 'landlord' && (
                  <li><span>Landlord Contact:</span> {to}<br/>
                    <ul>
                      <li>{contact.name}</li>
                      <li><a href={`mailto:${contact.email}`}>{contact.email}</a></li>
                      <li><a href={`tel:${contact.phone.link}`}>{contact.phone.text}</a></li>
                    </ul>
                  </li>
                )}
              </ul>
            </div>
            
            <div style={{width: '100%'}}>
              <Link to={back} className="back">Go Back</Link>
            </div>
          </Container>
        </Content>
      </StyledContainer>
    );
  }
}

const Content = styled.div`
  max-width: 480px;
  width: 100%;
  text-align: center;

  * {
    color: rgba(0,0,0,0.7);
  }

  a {
    color: #015c52;
  }

  h2 {
    font-size: 24px;
    margin-bottom: 30px;
  }

  p {
    font-size: 22px;
    margin: 20px 0;
  }

  img {
    max-width: 70px;
    margin: 0 auto;
  }

  .receipt {
    margin-top: 30px;
    padding: 60px 20px 30px;
    border: solid 1px #e5e5e5;
    border-radius: 4px;
    margin-bottom: 20px;
  }

 ul, ul li {
    text-align: left;
  }

  li span {
    color: #222;
    font-weight: 600;
  }

  a.back, p.print {
    width: 100%;
    margin: 0 auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: #1b998b;
    color: white;
    font-size: 18px;
    border: none;
    height: 48px;
    border-radius: 6px;
    cursor: pointer;
    -webkit-transition: .2s all;
    transition: .2s all;
    box-sizing: border-box;
  }

  p.print {
    background-color: transparent;
    border: solid 1px #e5e5e5;
    color: #666;
    transition: .2s;
    cursor: pointer;
  }

  p.print:hover {
    background-color: #fff;
  }
`;

PaymentReceipt = withRouter(PaymentReceipt);

export { PaymentReceipt };