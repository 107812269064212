import React from "react";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "../Button/Button";
import InputMask from "react-input-mask";
import { createTenant, updateUser, deleteUser } from "../../services/AppService";
import Toast from "../../shared/toast";

export default function TenantFormFields(props) {
  const { editing, tenant, propertyID } = props.context;

  const [values, setValues] = React.useState({
    first_name: editing ? tenant.first_name : "",
    last_name: editing ? tenant.last_name : "",
    email: editing ? tenant.email : "",
    confirm_email: editing ? tenant.email : "",
    phone: editing ? tenant.phone : ""
  });

  const listenForEnter = (event) => {
    if (event.which === 13 || event.keyCode === 13) {
      handleSubmit();
    }
  };

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleDeleteTenant = () => {
    const payload = {
      user_id: tenant.user_id,
      user_name: tenant.first_name,
      user_email: tenant.email
    };

    deleteUser({ payload }).then(result => {
      if (!result || result.status === "error") {
        const errorMessage =
          result.error || "Unexpected error. Please refresh the page and try again.";
        return Toast(errorMessage);
      }

      if (result.status === "success") {   
        localStorage.setItem('landlordRemovedTenant', result.user.user_name);
        window.location = `/landlord/property/${propertyID}/tenants`;
      }
    });
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      return Toast(`Please complete all form fields.`);
    }
    const obj = Object.assign({}, values);

    if (obj.email !== obj.confirm_email) {
      return Toast(`Emails don't match! Please check.`);
    }

    const payload = {
      ...values,
      email: values.email.toLowerCase(),
      auth_token: tenant.auth_token,
      base64Email: btoa(obj.email),
      propertyID: parseInt(propertyID)
    };

    if (editing) {
      updateUser({ payload }).then(result => {
        if (!result || result.status === "error") {
          const errorMessage =
            result.error || "Unexpected error. Please refresh the page and try again.";
          return Toast(errorMessage);
        }
  
        if (result.status === "success") {  
          setValues({ ...values, email: values.email.toLowerCase() }); 
          Toast('Successfully updated!');
        }
      });
    } else {
      createTenant({ payload }).then(result => {
        if (!result || result.status === "error") {
          const errorMessage =
            result.error || "Unexpected error. Please refresh the page and try again.";
          return Toast(errorMessage);
        }

        if (result.status === "success") {        
          localStorage.setItem('tenantAddingAddress', props.context.address);
          window.location = `/landlord/property/${propertyID}/tenant/added`;
        }
      });
    }
  };

  const validateForm = () => {
    let isPass = true;
    Object.values(values).forEach(item => {
      if (!item.replace(/\s/g, "")) isPass = false;
    });
    return isPass;
  };

  return (
    <Wrapper>
      <Container>
        <Row>
          <TextField
            className="form_item"
            label="First Name"
            value={values.first_name}
            onChange={handleChange("first_name")}
            onKeyUp={(e) => { listenForEnter(e) }}
          />
        </Row>
        <Row>
          <TextField
            className="form_item"
            label="Last Name"
            value={values.last_name}
            onChange={handleChange("last_name")}
            onKeyUp={(e) => { listenForEnter(e) }}
          />
        </Row>
        <Row>
          <TextField
            className="form_item"
            label="Email"
            type="email"
            value={values.email}
            onChange={handleChange("email")}
            onKeyUp={(e) => { listenForEnter(e) }}
          />
        </Row>
        <Row>
          <TextField
            className="form_item"
            label="Confirm Email"
            type="email"
            value={values.confirm_email}
            onChange={handleChange("confirm_email")}
            onKeyUp={(e) => { listenForEnter(e) }}
          />
        </Row>
        <Row>
          <InputMask
            mask="(999) 999-9999"
            value={values.phone}
            onChange={handleChange("phone")}
            onKeyUp={(e) => { listenForEnter(e) }}
          >
            {() => (
              <TextField
                id="phone"
                className="form_item"
                label="phone"
                name="phone"
                type="text"
              />
            )}
          </InputMask>
        </Row>
        <Row>
          <Button
            label={`${editing ? 'Update' : 'Add'} Tenant`}
            onClick={handleSubmit}
            style={{ width: "100%", marginTop: 20 }}
          />
        </Row>
        {editing && (
            <Button
              label="Remove Tenant"
              onClick={handleDeleteTenant}
              style={{ background: "none", width: "100%", marginTop: 12, color: "#b50202", fontSize: 17}}
            />
          )}
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .form_item {
    width: 100%;
  }
`;
