import React from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from '../Button/Button';
import { sendPasswordReset } from '../../services/AppService';
import { withGlobal } from '../Global/Global';
import { withRouter } from 'react-router-dom';
import Toast from '../../shared/toast';

function ForgotPasswordForm(props) {
  const [values, setValues] = React.useState({
    email: "",
    password: ""
  });

  const listenForEnter = (event) => {
    if (event.which === 13 || event.keyCode === 13) {
      handleSubmit();
    }
  };

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleSubmit = () => {
    sendPasswordReset({
      email: values.email,
      base64Email: btoa(values.email)
    }).then(result => {
      if (result.status === 'success'){
        props.history.push(`/password-reset-success`);
      } else {
        Toast("An account with this email doesn't exist.");
      }
    });
  };

  return (
    <Wrapper>
      <Container>
        <Row>
          <TextField
            className="form_item"
            label="Email"
            type="email"
            value={values.email}
            onChange={handleChange("email")}
            onKeyUp={(e) => { listenForEnter(e) }}
          />
        </Row>
        <Row>
          <Button
            stretch
            label="Reset Password"
            style={{ marginTop: 20 }}
            onClick={handleSubmit}
          />
        </Row>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .form_item {
    width: 100%;
  }
`;

export default withGlobal(withRouter(ForgotPasswordForm));
