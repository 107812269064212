import React from 'react';
import Container from 'react-bootstrap/Container';
import StyledContainer from "../StyledContainer/StyledContainer";
import styled from 'styled-components';
import { path } from "ramda";
import { Link } from 'react-router-dom';
import Logo from "../Logo/Logo";

export default function TenantAddedSuccess(props) {
  const propertyID = path(["match", "params", "propertyID"], props);
  const landlordID = props.context.currentUser.user_id;
  const address = localStorage.getItem('tenantAddingAddress');
  localStorage.removeItem('tenantAddingAddress');

  return (
    <StyledContainer style={{ background: '#f7f7f7', minHeight: "100vh" }}>
      <Content>
        <Container>
          <Logo flat style={{ marginBottom: 20 }}/>
          <h2>Tenant Added!</h2>
          <p>We have sent an email to your tenant. Your tenant can now pay you directly. Feel free to share this info with your tenant!</p>
          <p>Happy Renting!</p>
          <div style={{width: '100%', padding: '0 20px'}}>
            <AddProperty>
              <Link to={{
                  pathname:`/landlord/property/${propertyID}/tenants/add`,
                  state: {
                    landlordID,
                    propertyID,
                    address
                  }
                }}>Add Another Tenant</Link>
            </AddProperty>
            <BackView>
              <Link to={`/landlord/property/${propertyID}/tenants`}>View All Tenants</Link>
            </BackView>
          </div>
        </Container>
      </Content>
    </StyledContainer>
  );
}

const Content = styled.div`
  max-width: 480px;
  text-align: center;
  width: 100%;

  * {
    color: rgba(0,0,0,0.7);
  }

  a {
    color: #015c52;
  }

  h3 {
    opacity: 1;
    margin: 25px 0 0;
    font-size: 28px;
  }

  p {
    font-size: 22px;
    margin: 20px 0;
  }

  img {
    max-width: 70px;
    margin: 0 auto;
  }
`;

const AddProperty = styled.div`
  padding: 10px;
  width: 100%;
  border-top: solid 1px #f2f2f2;
  a.back {
    color: rgba(0,0,0,.7);
    border: solid 1px rgba(0,0,0,.5);
  }
  a {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: #1b998b;
    color: white;
    font-size: 18px;
    border: none;
    height: 48px;
    border-radius: 6px;
    cursor: pointer;
    -webkit-transition: .2s all;
    transition: .2s all;
    box-sizing: border-box;
  }
`;

const BackView = styled.div`
  text-align: center;
  padding: 0 10px;
  width: 100%;
  a {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: transparent;
    color: rgba(0,0,0,.7);
    border: solid 1px rgba(0,0,0,.5);
    font-size: 18px;
    height: 48px;
    border-radius: 6px;
    cursor: pointer;
    -webkit-transition: .2s all;
    transition: .2s all;
    box-sizing: border-box;
  }
`;