import React from "react";
import _ from 'lodash';
import styled from "styled-components";
import SimpleImage from "../SimpleImage/SimpleImage";
import TextField from "@material-ui/core/TextField";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "../Button/Button";
import InputMask from "react-input-mask";
import { updateUser } from "../../services/AppService";
import Toast from "../../shared/toast";

export default function PersonalInfoForm(props) {
  const { currentUser } = props.context;
  const photoURL = currentUser.photo || "/images/default_user.jpg";

  const userInfo = {
    first_name: currentUser.first_name,
    last_name: currentUser.last_name,
    email: currentUser.email,
    confirmEmail: currentUser.email,
    phone: currentUser.phone
  };

  const [values, setValues] = React.useState({
    ...userInfo
  });

  const listenForEnter = (event) => {
    if (event.which === 13 || event.keyCode === 13) {
      handleSubmit();
    }
  };

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleSubmit = async () => {
    const payload = {
      auth_token: currentUser.auth_token,
      ...values,
      email: values.email.toLowerCase()
    }; 

    const obj = Object.assign({}, values);

    if (obj.email !== obj.confirmEmail) {
      return Toast(`Emails don't match! Please check.`);
    }

    if (_.isEqual(values, userInfo)) {
      Toast("Successfully updated!");
      return;
    }

    const resp = await updateUser({ payload });
    if (resp.status === "success") {
      Toast("Successfully updated!");
      props.context.setCurrentUser({ currentUser: resp.user });
    }
  };

  return (
    <Wrapper>
      <Container className="uploadPhoto">
        <SimpleImage
          context={props.context}
          upload
          circle
          framed
          src={photoURL}
          darkBorder
          style={{ opacity: 0.85, width: 76, height: 76, marginBottom: 10 }}
        />
        <p>Press the image to change your photo</p>  
      </Container>
      <Container>
        <Row>
          <TextField
            className="form_item"
            label="First Name"
            value={values.first_name}
            onChange={handleChange("first_name")}
            onKeyUp={(e) => { listenForEnter(e) }}
          />
        </Row>
        <Row>
          <TextField
            className="form_item"
            label="Last Name"
            value={values.last_name}
            onChange={handleChange("last_name")}
            onKeyUp={(e) => { listenForEnter(e) }}
          />
        </Row>
        <Row>
          <TextField
            className="form_item"
            label="Email"
            type="email"
            value={values.email}
            onChange={handleChange("email")}
            onKeyUp={(e) => { listenForEnter(e) }}
          />
        </Row>
        <Row>
          <TextField
            className="form_item"
            label="Confirm Email"
            type="email"
            value={values.confirmEmail}
            onChange={handleChange("confirmEmail")}
            onKeyUp={(e) => { listenForEnter(e) }}
          />
        </Row>
        <Row>
          <InputMask
            mask="(999) 999-9999"
            value={values.phone}
            onChange={handleChange("phone")}
          >
            {() => (
              <TextField
                id="phone"
                className="form_item"
                label="phone"
                name="phone"
                type="text"
                onKeyUp={(e) => { listenForEnter(e) }}
              />
            )}
          </InputMask>
        </Row>
        <Row>
          <Button
            label="Update"
            onClick={handleSubmit}
            style={{ width: "100%", marginTop: 20 }}
          />
        </Row>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .form_item {
    width: 100%;
  }

  .uploadPhoto > div:first-of-type {
    margin: 0 auto 10px;
  }

  .uploadPhoto p {
    text-align: center;
    max-width: 175px;
    margin: 0 auto 20px;
    font-size: 15px;
  }
`;
