import React from 'react';
import { path } from "ramda";
import { Link } from 'react-router-dom';
import FontAwesome from "../FontAwesome/FontAwesome";
import styled from 'styled-components';
import TenantFormFields from './TenantFormFields';
import ViewHeader from '../ViewHeader/ViewHeader';

function TenantForm(props) {
  const formProps = {
    address: props.location.state.address,
    propertyID: props.location.state.propertyID,
    landlordID: props.location.state.landlordID,
    editing: props.location.state.editTenant || false,
    tenant: {
      ...props.location.state.tenant 
    }
  }; 

  const propertyID = path(["match", "params", "propertyID"], props);

  return (
    <Wrapper>
      <Link to={`/landlord/property/${propertyID}/tenants`} style={{position: 'absolute', left: 20, top: 38}} className="goback">
        <FontAwesome icon="fas fa-chevron-left" size={30} color="#5a53a1" />
      </Link>
      <ViewHeader title={`${props.location.state.editTenant ? 'Edit' : 'Add'} Tenant`}/>
      <p className='address'><small>Address</small>{props.location.state.address}</p>
      <FormContainer>
        <TenantFormFields context={formProps} />
      </FormContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  p.address {
    margin-top: -5px;
    color: rgba(0,0,0,.8);
  }
  p small {
    display: block;
    font-weight: 500;
    text-align: center;
  }
  p.address {
    max-width: 220px;
    text-align: center;
    margin: -5px 0 0;
  }
`;

const FormContainer = styled.div`
  background: white;
  width: 100%;
  padding: 10px 25px 25px;
`;

export default TenantForm;
