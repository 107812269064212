import React, { Component } from 'react';
import $ from 'jquery';
import FontAwesome from '../FontAwesome/FontAwesome';
import { ajaxForm } from 'jquery-form';
import { number, string, object, bool } from 'prop-types';
import styled from 'styled-components';
import { saveUserPhoto } from "../../services/AppService";
import Toast from '../../shared/toast';

export default class SimpleImage extends Component {
  state = {
    uploading: false,
    shouldSaveUserPhoto: {
      status: false,
      payload: null
    }
  };

  static propTypes = {
    maxWidth: number,
    context: object,
    src: string.isRequired,
    alt: string,
    style: object,
    circle: bool,
    framed: bool,
    shadow: bool,
    upload: bool,
    darkBorder: bool
  };

  static defaultProps = {
    maxWidth: 1024,
    alt: '',
    style: {}
  };

  componentDidMount() {
    this.uploadPhoto();
  }

  componentDidUpdate() {
    this.state.shouldSaveUserPhoto.status && this.handleSaveUserPhoto(this.state.shouldSaveUserPhoto.payload);
  }

  uploadPhoto() {
    const self = this;

    $("#upload-photo input[type='file']").on("change", function() {
      self.setState({ 
        uploading: true
      });

      $("#upload-photo").ajaxForm(rslt => {
        if (rslt && rslt !== 'error') {
          const payload = {
            photo: rslt,
            auth_token: self.props.context.currentUser.auth_token
          };

          self.setState({
            shouldSaveUserPhoto: {
              status: true,
              payload
            }
          });
        } else {
          Toast('Error uploading photo. Try again');
          return false;
        }
      }).submit();
    });
  }

  async handleSaveUserPhoto(payload) {
    const self = this;
    const resp = await saveUserPhoto({ payload });

    if (resp.status === "success") {
      setTimeout(function() {
          self.props.context.setCurrentUser({ 
          currentUser: {
            ...self.props.context.currentUser,
            photo: resp.user.photo
          }
        });
      }, 1500);
      Toast("Image updated successfully!");
    } else {
      Toast("Couldn't upload photo. Try again.");
    }
  }

  checkUpload(src, alt) {
    const orientation = src.split('[lp-orientation]')[0].replace('/user-images/', '');
    if (this.props.upload) {
      return (
        <form id="upload-photo" method="post" action="/includes/photo-upload.php" encType="multipart/form-data">
          <div style={{position: "relative", height: this.props.style.height, width: this.props.style.width}}>
              <div style={{
                height: "calc(100% + 3px)",
                width: "calc(100% + 3px)",
                position: "absolute",
                top: "-5px",
                left: "-5px",
                backgroundImage: `url(${src})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                transform: `rotate(${orientation}deg)`
                }}></div>
          </div>
          <FontAwesome
            className="uploading-photo"
            spin
            icon={"fa fa-spinner"}
            size={18}
            color="white"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: 70,
              height: 71,
              backgroundColor: "#342372",
              transition: 0.8,
              opacity: this.state.uploading ? '1' : '0'
            }}
          />
          <input className="file-input" type="file" name="images[]" />
        </form>
      );
    } else {
      return (
        <div style={{position: "relative", height: this.props.style.height, width: this.props.style.width}}>
          <div style={{
            height: "calc(100% + 3px)",
            width: "calc(100% + 3px)",
            position: "absolute",
            top: "-5px",
            left: "-5px",
            backgroundImage: `url(${src})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            transform: `rotate(${orientation}deg)`
            }}></div>
        </div>
      );
    }  
  }

  render() {
    const { maxWidth, src, alt, style, circle, framed, shadow, upload, darkBorder } = this.props;

    return (
      <Container
        maxWidth={maxWidth}
        circle={circle}
        framed={framed}
        shadow={shadow}
        upload={upload}
        darkBorder={darkBorder}
        style={{ ...style }}
      >
        {this.checkUpload(src, alt)}
      </Container>
    );
  }
}
const Container = styled.div`
  max-width: 1024px;
  .responsive {
    width: 100%;
    height: auto;
  }

  #upload-photo {
    position: relative;
  }

  #upload-photo .file-input {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
  }

  .admin {
    position: fixed;
    width: 100%;
    top: 55px;
    left: 0;
    padding: 5px;
    color: #000;
    text-align: center;
    background-color: #fff;
  }

  ${props =>
    props.maxWidth &&
    `
    max-width: ${props.maxWidth}px;
  `}

  ${props =>
    props.circle &&
    `
    overflow: hidden;
    border-radius: 50%;
  `}

  ${props =>
    props.framed &&
    `
    border: 3px solid white !important;
  `}

  ${props =>
    props.darkBorder &&
    `
    border-color: #9f9f9f !important;
  `}

  ${props =>
    props.shadow &&
    `
    box-shadow: 0px 3px 15px -3px rgba(0,0,0,.3);
  `}
`;
