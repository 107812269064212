import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import moment from 'moment'
import FontAwesome from '../FontAwesome/FontAwesome'

function PaymentCard(props) {
	const { payment, mode } = props
	const { tenant_name, landlord_name, date, description } = payment

	let { amount } = payment

	if (date > 1661990400) {
		props.mode === 'landlord' && (amount = amount - 699)
	} else if (date > 1596045540) {
		props.mode === 'landlord' && (amount = amount - 199)
	} else {
		props.mode === 'landlord' && (amount = amount - 100)
	}

	const isAuthnet = payment.receipt_url.indexOf('stripe') >= 0 ? false : true
	let receipt_url = isAuthnet
		? `/${mode}${payment.receipt_url}`
		: payment.receipt_url

	const amountInDollars = `$${(amount / 100).toLocaleString(undefined, {
		minimumFractionDigits: 2,
	})}`

	return (
		<Container>
			<div className="payment-card-date">{moment.unix(date).format('LLL')}</div>
			<div className="payment-card-info">
				<div className="payment-card-icon">
					<FontAwesome icon="fas fa-dollar-sign" size={20} color="#4CAF50" />
				</div>
				<div className="content">
					<div style={{ fontWeight: 600, fontSize: 18, letterSpacing: 0.3 }}>
						{props.mode === 'landlord' ? tenant_name : landlord_name}
					</div>
					<div
						style={{ padding: '3px 0', fontSize: 17 }}
						className="payment-amount-dollars"
					>
						{amountInDollars}
					</div>
					<div className="actions">
						<Link
							to={{
								pathname: receipt_url,
								state: {
									mode: mode,
									back: window.location.pathname,
									to: landlord_name,
									from: tenant_name,
									status: 'Paid',
									date: moment.unix(date).format('LLL'),
									amount: amountInDollars,
									description: description.split(':')[1],
									contact: {
										name: 'Little Properties',
										email: 'lindalittle@san.rr.com',
										phone: {
											link: 'tel:+18583423793',
											text: '+1 858-342-3793',
										},
									},
								},
							}}
							target={!isAuthnet ? '_blank' : ''}
						>
							Receipt
						</Link>
					</div>
				</div>
			</div>
		</Container>
	)
}

const Container = styled.div`
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1px;
	width: 100%;
	flex-wrap: wrap;

	.payment-card-info {
		width: 100%;
		padding: 20px 25px 35px;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}

	.content {
		width: 100%;
		text-align: left;
		padding: 0 15px;
	}

	.actions {
		display: block;
		width: 100%;
	}

	.payment-card-icon {
		border-radius: 50%;
		padding: 3px 3px 2.5px;
		border: solid 1px #e5e5e5;
	}

	.payment-card-date {
		width: 100%;
		padding: 8px 25px;
		color: #555;
		font-size: 16px;
		border-top: solid 1px #f2f2f2;
		border-bottom: solid 1px #f2f2f2;
	}

	a {
		border-radius: 32px;
		height: 32px;
		line-height: 33px;
		text-align: center;
		padding: 0px 14px;
		color: #ffffff;
		background-color: #4cae50;
		font-weight: 600;
		max-width: 200px;
		display: block;
		margin: 15px 0 -10px;
	}

	.payment-amount-dollars {
		opacity: 0.85;
	}
`

export default PaymentCard
