import React from 'react';
import styled from 'styled-components';

export const GlobalStyles = props => (
  <GlobalStylesContainer>{props.children}</GlobalStylesContainer>
);

const GlobalStylesContainer = styled.div`
  position: absolute;
  min-height: 100% !important;
  width: 100% !important;
  top: 0;
  left: 0;
  font-size: 18px;
  font-family: 'Avenir Next', 'Open Sans', sans-serif;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 500;
  background-color: #fff;

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .heavy {
    font-weight: 600;
  }

  .uc {
    text-transform: capitalize;
  }

  a {
    color: white;
    text-decoration: none!important;
  }

  input[type="email"] {
    text-transform: lowercase!important;
  }

  h1 {
    font-size: 52px;
    margin-bottom: 20px;
    line-height: 1.2em;
  }

  h3 {
    font-size: 16px;
    font-weight: 500;
    opacity: 0.8;
    line-height: 0.8em;
  }

  h4 {
    font-size: 16px;
    font-weight: 500;
    opacity: 0.8;
    line-height: 0.8em;
  }

  p {
    font-size: 17px;
    letter-spacing: normal;
    line-height: 1.6em;
    opacity: 0.8;
  }

  .xl {
    font-size: 23px;
  }

  .lg {
    font-size: 19px;
  }

  .md {
    font-size: 17px;
  }

  .sm {
    font-size: 15px;
  }

  .xs {
    font-size: 13px;
  }

  .home-footer p {
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 15px;
    opacity: .5;
  }

  .not-home {
    height: auto;
    padding: 30px 20px 25px;
    background-color: #f2f2f2;
  }

  @media all and (max-width: 767px) {
    .home-footer:not(.expandContent) {
      height: auto;
      padding: 30px 20px 25px;
      background-color: #f2f2f2;
    }
    .home-footer p {
      text-align: center;
      top: 0;
      width: 100%;
      margin: 0;
      color: #555;
      line-height: 36px;
      text-transform: none;
      opacity: 1;
      font-weight: 500;
    }
    .home-footer a {
      margin: 0 0 5px;
    }
  }

  .dash-section {
    padding: 34px 20px 25px;
  }

  .dash-section h3 {
    font-size: 16px;
    top: -5px;
    margin: 0;
    position: relative;
  }

  .dash-section span {
    font-size: 20px;
    display: block;
    padding: 2px 0 0;
  }

  @media all and (max-width: 575px) {
    h1 {
      font-size: 34px;
    }

    .xl {
      font-size: 18px;
    }

    .lg {
      font-size: 15px;
    }

    .md {
      font-size: 13px;
    }

    .sm {
      font-size: 11px;
    }

    .xs {
      font-size: 9px;
    }
  }
`;
