import React from 'react';
import styled from 'styled-components';

export const Logo = props => (
  <Container size={props.size} style={{ ...props.style }}>
    <img src={`/images/lp-logo${props.flat ? '-flat' : ''}.svg`} alt="lp-logo" className="responsive" />
  </Container>
);

const Container = styled.div`
  max-width: 1024px;
  .responsive {
    width: 100%;
    height: auto;
  }

  ${props =>
    props.size &&
    `
    width: ${props.size}px;
    height: ${props.size}px;
  `}
`;

export default Logo;
