import React from 'react';
import { path } from "ramda";
import { withRouter, Link } from 'react-router-dom';
import styled from 'styled-components';
import SimpleImage from '../SimpleImage/SimpleImage';

function UserCard(props) {
  const user = props.user;
  const photoURL = user.photo || '/images/default_user.jpg';
  const propertyID = path(["match", "params", "propertyID"], props);

  let phone = user.phone;
  phone = phone.replace(/-/g, '').replace('(','').replace(')','');

  return (
    <Container>
      <div className="photo">
        <SimpleImage framed circle context={props.context} src={photoURL} style={{ opacity: 0.85, width: 52, height: 52 }} />
      </div>
      <div className="content">
        <div className="name">
          {user.first_name} {user.last_name}
        </div>
        <div className="actions">
          <div className="edit">
            <Link to={{
              pathname:`/landlord/property/${propertyID}/${user.user_id}/edit`,
              state: {
                address: props.address,
                propertyID: parseInt(props.propertyID),
                landlordID: props.landlordID,
                editTenant: true,
                tenant: {
                  ...user
                }
              }
            }}>Edit</Link>
          </div>
          <div className="email"><a href={`mailto:${user.email}`}>Email</a></div>
          <div className="phone"><a href={`tel:+1${phone}`}>Call</a></div>
        </div>
      </div>
    </Container>
  );
}

const Container = styled.div`
  box-sizing: border-box;
  padding: 20px 25px 35px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: #fff;
  border-top: solid 1px #f2f2f2;

  .content {
    width: 100%;
    text-align: left;
    padding: 0 5px 0 15px;
  }

  .name {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .actions {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .actions a {
    display: block;
    line-height: 1.7;
    padding: 4px 16px 3px;
    margin-right: 6px;
    font-size: 16px;
    background-color: #f7f7f7;
    border-radius: 4px;
    font-weight: 600;
    border: solid 1px #d5d5d5;
    color: #00685c;
  }

  .edit a {
    background-color: #1b998b;
    border-color: #1b998b;
    color: #fff;
  }

  .phone a {
    margin-right: 0;
  }
`;

export default withRouter(UserCard);
