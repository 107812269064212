import React from "react";
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Button from '../Button/Button';
import Container from 'react-bootstrap/Container';
import StyledContainer from "../StyledContainer/StyledContainer";
import styled from "styled-components";
import { Link } from "react-router-dom";

function HowItWorks() {
  if (window.location.search !== "?showpage=yessir") {
    window.location = "/";
  }
  return (
    <>
      <Header />
      <StyledContainer>
        <Content>
          <Container className="titleContent" style={{ minHeight: 210 }}>
            <h1>Let's get you paid.</h1>
            <h3>Secure, fast, and easy.</h3>
            {window.location.pathname.indexOf('how-it-works') && (
              <Link className="signUp" to="/sign-up">
                <Button label="Sign Up" mega style={{ fontSize: 23, marginBottom: 20 }} />
              </Link>
            )}
          </Container>
        </Content>
      </StyledContainer>
      <div className="howToVideos" style={{ padding: '60px 20px', backgroundColor: '#f6f6f6' }}>
        {HowToVideo({
          title: "Welcome Video",
          description: "Watch this video to learn about our company and to get an overview of how things work.",
          url: "https://www.youtube.com/watch?v=Qlmal7x7SYo"
        })}
      </div>
      <Content>
        <Container className="bodyContent"> 
          {HowToSection({
            title: "Who is this for?",
            content: "If you own any type of rental property, parcel of land, or storage unit this is for you."
          })}
          {HowToVideo({
            title: "Welcome Video",
            description: "Watch this video to learn about our company and to get an overview of how things work.",
            url: "https://www.youtube.com/watch?v=Qlmal7x7SYo"
          })}
          {HowToSection({
            title: "How many properties can I add?",
            content: "You can add an unlimited amount of property addresses"
          })}

          {HowToSection({
            title: "How do I add my tenants?",
            content: "After adding a property you can add tenants right away. We will send them an email so they can sign into their account and pay you continually."
          })}

          {HowToSection({
            title: "How do I get paid?",
            content: "Anyone who rents property from you will be able to enter an amount and pay you online."
          })}

          {HowToSection({
            title: "When will I recieve the funds?",
            content: "Payments will post to your bank account in approximately 3 days."
          })}

          {HowToSection({
            title: "Does this service take a portion of what I charge renters/tenants?",
            content: "Absolutely not. The amount you charge your tenants is up to you and we won't take one penny from you... ever."
          })}

          {HowToSection({
            title: "Is there a tax for transferring money to my bank account?",
            content: "Yes, there is a 3% Fee charged to you for accepting online payments. We don't receive this money, it is a tax collected by the payment processor and is 100% tax deductible at the end of the year."
          })}

          {HowToSection({
            title: "How can I avoid the online payment tax?",
            content: "You can increase your monthly rent by 3%. You can also deduct these fees annually when you file taxes."
          })}

          {HowToSection({
            title: "Will I recieve Monthly & Yearly statements?",
            content: "Yes! We will give you monthly & yearly statements so you can recieve all income taxes on your in your tax return."
          })}
        </Container>
      </Content>
      <Footer />
    </>
  );
}

const HowToSection = ({ title, content }) => (
  <Section className='dash-section' style={{ marginBottom: 16 }}>
    <h2>{title}</h2>
    <p>{content}</p>
  </Section>
);

const HowToVideo = ({ title, description, url }) => (
  <Section className='dash-section'>
    <div className="video-container">
      <h2 style={{marginBottom: 15}}>{title}</h2>
      <video width="100%" style={{minHeight: 250}} controls preload="true">
        <source src={url} type="video/mp4" />
      </video>
      <p style={{marginTop: 15}}>{description}</p>
    </div>
  </Section>
);

const Section = styled.div`
  text-align: center;
  padding: 35px 20px 30px;
  background: white;
  color: rgba(0, 0, 0, 0.7);

  p {
    font-size: 22px;
    line-height: 1.6;
  }

  ${props =>
    props.transparent &&
    `
    background: none;
  `}
`;

const Content = styled.div`
  max-width: 700px;
  margin: 64px auto 0;
  text-align: center;

  .titleContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .titleContent h1 {
    font-size: 42px;
    margin-bottom: 15px;
  }

  .titleContent h3 {
    font-size: 28px;
  }

  .titleContent .signUp {
    width: 100%;
    margin-top: 20px;
    display: none;
  }

  .bodyContent {
    padding-bottom: 50px;
  }

  @media all and (min-width: 1025px) {
    .titleContent .signUp {
      display: block;
    }

    .titleContent {
      padding: 50px 0 20px;
    }
  }

  @media all and (max-width: 480px) and (min-width: 351px) {
    .titleContent h1 {
      font-size: 34px;
    }

    .titleContent h3 {
      font-size: 24px;
    }
  }

  @media all and (max-width: 350px) {
    .titleContent h1 {
      font-size: 29px;
    }

    .titleContent h3 {
      font-size: 20px;
    }
  }
`;

export default HowItWorks;
