import React from 'react';
import { GlobalStyles } from './ui/AppStyles/AppStyles';
import AppRoutes from './routes/AppRoutes';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <GlobalStyles>
      <ToastContainer hideProgressBar autoClose={false} />
      <AppRoutes />
    </GlobalStyles>
  );
}

export default App;
