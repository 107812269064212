import React, { Fragment } from 'react';
import styled from 'styled-components';
import SimpleImage from '../SimpleImage/SimpleImage';
import { Link } from 'react-router-dom';

function UserHeader(props) {
  const { currentUser } = props.context;
  const photoURL = currentUser.photo || '/images/default_user.jpg';
  return (
    <Wrapper>
      <Link to={`/${currentUser.type}/dashboard`}>
        <ButtonContainer>
          <SimpleImage
            context={props.context}
            shadow
            circle
            framed
            src={photoURL}
            style={{ opacity: 0.85, width: 52, height: 52 }}
          />
        </ButtonContainer>
      </Link>
    </Wrapper>
  );
}

export function withUserHeader(Component) {
  return function WrapperComponent(props) {
    return (
      <Fragment>
        <UserHeader context={props.context} />
        <Component {...props} />
      </Fragment>
    );
  };
}

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #160d70;
  color: white;
`;

const ButtonContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 52px;
  width: 52px;
  margin: 32px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s all;
  cursor: pointer;
  z-index: 100;

  &:hover {
    transform: scale(1.2);
  }
`;
