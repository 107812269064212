import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import FontAwesome from '../FontAwesome/FontAwesome'
import moment from 'moment'
import { path } from 'ramda'
import styled from 'styled-components'
import ViewHeader from '../ViewHeader/ViewHeader'
import { fetchPayments, getPropertyWithID } from '../../services/AppService'
import MonthYearCard from '../MonthYearCard/MonthYearCard'

class LandlordPaymentHistory extends Component {
	state = {
		propertyID: null,
		property: null,
		payments: null,
	}

	async componentWillMount() {
		const propertyID = path(['match', 'params', 'propertyID'], this.props)
		const { user_id: landlordID, auth_token } = this.props.context.currentUser
		const resp = await fetchPayments({ auth_token })
		const resp2 = await getPropertyWithID(landlordID, propertyID)
		if (resp.status === 'success' && resp2.status === 'success') {
			this.setState({
				propertyID,
				payments: resp.rows,
				property: resp2.property,
			})
		}
	}

	handlePaymentDates() {
		const { payments, propertyID, property } = this.state
		const paymentsLength = payments.length
		const months = []
		const monthTotals = {}
		const propertyRentAmount = property.rent_amount
		const propertyAddress = property.property_address
		let x, curMonth, monthKey, curDay, dateArr
		for (x = 0; x < paymentsLength; x++) {
			if (parseInt(propertyID) === payments[x].property_id) {
				curMonth = moment.unix(payments[x].date).format('MMMM D YYYY')
				dateArr = curMonth.split(' ')
				curDay = dateArr[1]
				if (curDay > 10) {
					curMonth = moment
						.unix(payments[x].date)
						.add(1, 'M')
						.format('MMMM YYYY')
				} else {
					curMonth = dateArr[0] + ' ' + dateArr[2]
				}
				monthKey = curMonth.replace(' ', '_').toLowerCase()
				if (monthTotals[monthKey]) {
					if (payments[x].date > 1661990400) {
						monthTotals[monthKey].amount += payments[x].amount - 699
					} else if (payments[x].date > 1596045540) {
						monthTotals[monthKey].amount += payments[x].amount - 199
					} else {
						monthTotals[monthKey].amount += payments[x].amount - 100
					}
				} else {
					let paymentAmount
					if (payments[x].date > 1661990400) {
						paymentAmount = payments[x].amount - 699
					} else if (payments[x].date > 1596045540) {
						paymentAmount = payments[x].amount - 199
					} else {
						paymentAmount = payments[x].amount - 100
					}

					monthTotals[monthKey] = {
						name: curMonth,
						amount: paymentAmount,
						propertyID: propertyID,
						propertyRentAmount,
					}
				}
			}
		}

		Object.keys(monthTotals).forEach(key => {
			months.push(monthTotals[key])
		})

		const houseInfo = (
			<Fragment>
				<p className="address">{propertyAddress}</p>
				<p className="monthly-rent">
					Monthly Rent:{' '}
					{`$${(propertyRentAmount / 100).toLocaleString(undefined, {
						minimumFractionDigits: 2,
					})}`}
				</p>
			</Fragment>
		)

		const paymentList = months.map((item, index) => {
			return <MonthYearCard key={index} mode="landlord" item={item} />
		})

		return (
			<Fragment>
				{houseInfo}
				{paymentList}
			</Fragment>
		)
	}

	render() {
		const { payments } = this.state

		if (!payments) return null

		const hasPayments = payments.length > 0

		return (
			<Wrapper>
				<Link
					to="/landlord/payment-history"
					style={{ position: 'absolute', left: 20, top: 38 }}
					className="goback"
				>
					<FontAwesome icon="fas fa-chevron-left" size={30} color="#5a53a1" />
				</Link>
				<ViewHeader title="Monthly Overview" />
				{hasPayments && this.handlePaymentDates()}
				{!hasPayments && <NoneFoundView>No Payment History</NoneFoundView>}
			</Wrapper>
		)
	}
}

const Wrapper = styled.div`
	min-height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: #fff;

	.address {
		max-width: 200px;
		margin: -5px auto 8px;
		text-align: center;
		text-transform: uppercase;
		font-size: 14px;
		letter-spacing: 0.3px;
		font-weight: 600;
	}

	.monthly-rent {
		font-weight: 600;
		color: #444;
		opacity: 1;
		margin-bottom: 25px;
	}
`

const NoneFoundView = styled.div`
	height: 120px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-style: italic;
	font-size: 22px;
	opacity: 0.5;
	font-weight: 600;
`

export default LandlordPaymentHistory
