import React from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from '../Button/Button';
import { login } from '../../services/AppService';
import { withGlobal } from '../Global/Global';
import { withRouter } from 'react-router-dom';
import Toast from '../../shared/toast';

function LoginForm(props) {
  const [values, setValues] = React.useState({
    email: "",
    password: ""
  });

  const listenForEnter = (event) => {
    if (event.which === 13 || event.keyCode === 13) {
      handleSubmit();
    }
  };

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleSubmit = () => {
    login({
      email: values.email,
      password: values.password
    }).then(result => {
      if (result && result.status === "success") {
        localStorage.setItem(
          process.env.REACT_APP_SESSION_TOKEN,
          result.user.auth_token
        );
        localStorage.setItem('isLoggedIn', 'true');
        props.context.setData({ currentUser: result.user, isLoggedIn: true });
        props.history.push(`/${result.user.type}/payment`);
      } else {
        localStorage.removeItem(process.env.REACT_APP_SESSION_TOKEN);
        Toast(
          "Incorrect Login. Try again."
        );
      }
    });
  };

  return (
    <Wrapper>
      <Container>
        <Row>
          <TextField
            className="form_item"
            label="Email"
            type="email"
            value={values.email}
            onChange={handleChange("email")}
            onKeyUp={(e) => { listenForEnter(e) }}
          />
        </Row>
        <Row>
          <TextField
            className="form_item"
            label="Password"
            type="password"
            value={values.password}
            onChange={handleChange("password")}
            onKeyUp={(e) => { listenForEnter(e) }}
          />
        </Row>
        <Row>
          <Button
            stretch
            label="Login"
            style={{ marginTop: 20 }}
            onClick={handleSubmit}
          />
        </Row>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .form_item {
    width: 100%;
  }
`;

export default withGlobal(withRouter(LoginForm));
