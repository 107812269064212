import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { 
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement, 
  injectStripe 
} from "react-stripe-elements";
import { func } from "prop-types";
import Button from "../Button/Button";
import Toast from "../../shared/toast";

class PaymentElement extends Component {
  static propTypes = {
    setToken: func.isRequired
  };

  state = {
    isLoading: false
  };

  handleSubmit = ev => {
    this.setState({ isLoading: true });
    this.props.stripe.createToken().then(response => {
      if (response.token) {
        this.setState(
          {
            isLoading: false,
            token: response.token
          },
          () => this.props.setToken(response.token)
        );
      } else {
        this.setState({ isLoading: false });
        if (response.error) {
          Toast(response.error.message);
        }
      }
    });
  };

  renderElementType() {
    if (this.props.smallPhone) {
      return (
        <Fragment>
          <div className="cardElementRow">
            <CardNumberElement style={{ base: { fontSize: "20px" }}} />
          </div>
          <div className="cardElementRow">
            <CardExpiryElement style={{ base: { fontSize: "20px" }}} />
          </div>
          <div className="cardElementRow">
            <CardCvcElement style={{ base: { fontSize: "20px" }}} />
          </div>
        </Fragment>
      );
    } else {
      return (
        <div className="cardElementRow">
          <CardElement
            hidePostalCode={true}
            style={{
              base: {
                fontSize: "20px",
                borderRadius: "3px"
              }
            }}
          />
        </div>
      );
    }
  }

  render() {
    return (
      <>
        <Wrapper>
          <p className="cardInfo">Card Info <small>Powered by Stripe</small></p>
          {this.renderElementType()}
        </Wrapper>

        <Button
          label="Continue"
          stretch
          mega
          style={{ background: "#4036ad" }}
          onClick={this.handleSubmit}
        />
      </>
    );
  }
}

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 15px;

  .cardInfo {
    font-size: 20px;
    margin-bottom: 5px;
  }

  small {
    display: block;
    opacity: .7;
    margin-top: -5px;
    font-size: 16px;
    text-align: center;
  }

  .cardElementRow {
    padding: 14px 10px 12px;
    margin-bottom: 10px;
    background-color: #fff;
    border-bottom: solid 1px #e5e5e5;
    border-radius: 2px;
  }
`;

export default injectStripe(PaymentElement);
