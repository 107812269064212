import React from "react";
import styled from "styled-components";

const StyledContainer = props => (
  <Container style={props.style}>{props.children}</Container>
);

const Container = styled.div`
  background: #160d70;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("/images/lp-background.svg");
  background-size: cover;
  background-position: bottom;
  padding: 20px;
  min-height: 210px;
`;

export default StyledContainer;
