// 9/2: This file is fine for now. Once the property form is complete i'll have to come back to this file and gut all the form stuff.
import React, { Component } from "react";
import FontAwesome from "../FontAwesome/FontAwesome";
import _ from 'lodash';
import styled from "styled-components";
import queryString from "query-string";
import { Link } from "react-router-dom";
import PropertyCard from "../PropertyCard/PropertyCard";
import ViewHeader from "../ViewHeader/ViewHeader";
import {
  propertiesForLandlordID,
  createProperty
} from "../../services/AppService";
import Toast from "../../shared/toast";

class LandlordProperties extends Component {
  state = {
    mode: "address",
    properties: null,
    newPropertyAddress: {
      line1: "",
      line2: "",
      city: "",
      state: "",
      zip: ""
    },
    newPropertyRentAmount: ""
  };

  async componentWillMount() {
    const params = queryString.parse(this.props.location.search);
    this.reloadProperties(params.mode);
  }

  async reloadProperties(mode) {
    const { currentUser } = this.props.context;
    const resp = await propertiesForLandlordID(currentUser.user_id);
    if (resp.status === "success") {
      this.setState({
        mode,
        properties: resp.rows
      });
    }
  }

  handleAddProperty = () => {
    const { newPropertyAddress, newPropertyRentAmount } = this.state;
    let clear = true;

    _.each(newPropertyAddress, (value, field) => {
        if (!value && field !== 'line2') {
          clear = false;
          Toast("All address fields are required");
          return false;
        }
    });

    if (!clear) {
      return;
    }


    let address = Object.assign({}, newPropertyAddress);
    
    if (address.line2.length > 0) {
      address.line2 = address.line2 + ', ';
    } 

    address =
        _.trim(address.line1) + ', '
        + _.trim(address.line2)
        + _.trim(address.city) + ', '
        + _.trim(address.state) + ' '
        + _.trim(address.zip);

    if (address.length < 10) {
      Toast("The address entered appears to be invalid.");
      return;
    }

    // Validate Rent Amount
    if (isNaN(parseInt(newPropertyRentAmount)) || newPropertyRentAmount < 100 || newPropertyRentAmount > 999999) {
      return Toast("Please specify a rent amount between $100 - $9999");
    }

    const { currentUser } = this.props.context;
    const rentInCents = parseInt(newPropertyRentAmount * 100, 10);

    const payload = {
      propertyAddress: address,
      propertyLandlordID: currentUser.user_id,
      propertyRentAmount: rentInCents
    };

    createProperty({ payload }).then(result => {
      if (result.status === "success") {
        this.resetForm();
        this.reloadProperties(this.state.mode);
        Toast("Successfully added address!");
      } else if (result.error) {
        Toast(result.error);
      }
    });
  };

  resetForm = () => {
    this.setState({ 
      newPropertyAddress: {
        line1: "",
        line2: "",
        city: "",
        state: "",
        zip: ""
      },
      newPropertyRentAmount: ""
    });
  };

  render() {
    const { properties, mode } = this.state;

    if (localStorage.justAdded === 'true') {
      Toast("Successfully added property!");
      localStorage.setItem('justAdded', 'false');
    } else if (localStorage.justUpdated === 'true') {
      Toast("Successfully updated property!"); 
      localStorage.setItem('justUpdated', 'false');      
    }

    if (!properties) return null;

    const hasProperties = properties.length > 0;

    const viewTitle = mode === 'tenant' ? 'Manage Tenants' : 'Manage Properties';

    return (
      <Wrapper>
        <Link to="/landlord/dashboard" style={{position: 'absolute', left: 20, top: 38}} className="goback">
          <FontAwesome icon="fas fa-chevron-left" size={30} color="#5a53a1" />
        </Link>
        <ViewHeader title={viewTitle} />
        {mode === 'tenant' && (
          <p className='help'>Choose a property below to manage tenants</p>
        )}
        {mode !== 'tenant' && (
          <AddProperty>
            <Link to="/landlord/property/0/add">Add a Property</Link>
          </AddProperty>
        )}
        <PropertiesListView>
          {properties.map(row => (
            <PropertyCard key={row.property_id} property={row} mode={mode} />
          ))}
        </PropertiesListView>
        {!hasProperties && <NoneFoundView>No Properties</NoneFoundView>}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  min-height: 100%;
  background: #fff;
  .form_item {
    width: 100%;
  }
  .form_item input {
    text-transform: capitalize !important;
  }
  #state {
    text-transform: uppercase !important;
  }
  p {
    text-align: center;
    color: rgba(0,0,0,.7);
  }
  p.help {
    max-width: 200px;
    font-size: 16px;
    color: rgba(0,0,0,.9);
    margin: -5px auto 20px;
  }
`;

const PropertiesListView = styled.div`
  width: 100%;
  > div {
    margin-bottom: 1px;
  }
`;

const AddProperty = styled.div`
  padding: 10px;
  border-top: solid 1px #f2f2f2;
  a {
    max-width: 480px;
    margin: 0 auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: #1b998b;
    color: white;
    font-size: 18px;
    border: none;
    height: 48px;
    border-radius: 6px;
    cursor: pointer;
    -webkit-transition: .2s all;
    transition: .2s all;
    box-sizing: border-box;
  }
`;

const NoneFoundView = styled.div`
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: italic;
  font-size: 22px;
  opacity: .7;
  font-weight: 600;
`;

export default LandlordProperties;






