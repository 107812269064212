import React, { Component } from "react";
import _ from 'lodash';
import { logout } from "../services/AppService";

export const AppContext = React.createContext();

export class AppProvider extends Component {
  state = {
    currentUser: null,
    properties: [],
    isLoggedIn: false
  };

  logout = () => {
    const { currentUser } = this.state;

    logout({ auth_token: currentUser.auth_token }).then(() => {
      localStorage.removeItem(process.env.REACT_APP_SESSION_TOKEN);
      localStorage.setItem('isLoggedIn', 'false');
      this.setState({ isLoggedIn: false });
      window.location = "/";
    });
  };

  render() {
    const self = this;
    const context = {
      ...this.state,
      setData: payload => this.setState({ ...payload }),
      setCurrentUser: payload => {
          self.setState({
            currentUser: {
              ..._.merge(self.state.currentUser, payload.currentUser)
            }
          });
      },
      logout: this.logout
    };

    return (
      <AppContext.Provider value={context}>
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export function withAppContext(Component) {
  return function WrapperComponent(props) {
    return (
      <AppContext.Consumer>
        {context => <Component {...props} context={context} />}
      </AppContext.Consumer>
    );
  };
}
