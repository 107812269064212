import React, { Component } from "react";
import { Link } from 'react-router-dom';
import FontAwesome from "../FontAwesome/FontAwesome";
import _ from 'lodash';
import styled from "styled-components";
import { path } from "ramda";
import TextField from "@material-ui/core/TextField";
import Button from "../Button/Button";
import ViewHeader from "../ViewHeader/ViewHeader";
import {
  propertiesForLandlordID,
  updateProperty,
  createProperty,
  deleteProperty
} from "../../services/AppService";
import Toast from "../../shared/toast";

class PropertyForm extends Component {
  state = {
    mode: "address",
    creating: false,
    propertyID: 0,
    newPropertyAddress: {
      line1: "",
      line2: "",
      city: "",
      state: "",
      zip: ""
    },
    newPropertyRentAmount: ""
  };

  async componentWillMount() {
    this.updateProperty = path(["match", "params", "propertyID"], this.props);
    this.addProperty = path(["match", "params", "formID"], this.props)

    if (this.addProperty) {
      this.formType = 'creating';
      this.reloadPropertyForm(this.addProperty);
    } else if (this.updateProperty) {
      this.formType = 'updating';
      this.reloadPropertyForm(this.updateProperty);
    } 
  }

  async reloadPropertyForm(param) {
    const { currentUser } = this.props.context;

    if (this.formType === 'creating') {
      this.setState({
        creating: true
      });
    } else if (this.formType === 'updating') {
      const resp = this.formType === 'updating' ? await propertiesForLandlordID(currentUser.user_id) : false;

      if (!resp || isNaN(parseInt(param))) {
        return;
      }

      const selectedProperty = resp.rows.find(
        row => row.property_id === parseInt(param, 10)
      );

      if (resp.status === "success") {

        let address = "";
        address = selectedProperty.property_address;
        address = address.split(',');
        
        if (address.length === 3) {
          address.splice(1, 0, '');
        } 
  
        let line1 = address[0];
        let line2 = address[1];
        let city = address[2];
        let state = address[3].split(' ')[1];
        let zip = address[3].split(' ')[2];
  
        this.setState({
          propertyID: selectedProperty.property_id,
          newPropertyAddress: {
            line1,
            line2,
            city,
            state,
            zip
          },
          newPropertyRentAmount: '$' + parseInt(selectedProperty.rent_amount / 100, 10).toLocaleString()
        });
      }
    }
  }

  listenForEnter(event) {
    if (event.which === 13 || event.keyCode === 13) {
      this.handlePropertyForm();
    }
  }

  handlePropertyForm = () => {
    const { propertyID, newPropertyAddress, newPropertyRentAmount } = this.state;
    let clear = true;

    _.each(newPropertyAddress, (value, field) => {
        if (!value && field !== 'line2') {
          clear = false;
          Toast("All address fields are required");
          return false;
        }
    });

    if (!clear) {
      return;
    }

    let address = Object.assign({}, newPropertyAddress);
    
    if (address.line2.length > 0) {
      address.line2 = address.line2 + ', ';
    } else {
      address.line2 = _.trim(address.line2);
    }

    address =
        _.trim(address.line1) + ', '
        + address.line2
        + _.trim(address.city) + ', '
        + _.trim(address.state) + ' '
        + _.trim(address.zip);

    if (address.length < 10) {
      Toast("The address entered appears to be invalid.");
      return;
    }

    let numAmount = parseInt(newPropertyRentAmount.replace('$', '').replace(/,/g, ''));

    if (isNaN(parseInt(numAmount)) || numAmount < 100 || numAmount > 99999) {
      return Toast("Rent must be $100 - $99,999");
    }

    const { currentUser } = this.props.context;
    const rentInCents = parseInt(numAmount * 100, 10);

    const payload = {
      propertyAddress: address,
      propertyLandlordID: currentUser.user_id,
      propertyRentAmount: rentInCents
    };

    if (this.formType === 'updating') {
      this.handleUpdateProperty(payload, propertyID, currentUser);
    } else if (this.formType === 'creating') {
      this.handleAddProperty(payload);
    }
  };

  handleAddProperty = (payload) => {

    createProperty({ payload }).then(result => {
      if (result.status === "success") {
        this.resetForm();
        localStorage.setItem('justAdded', 'true');
        setTimeout(() => {
          window.location = '/landlord/properties?mode=address';
        }, 500);
      } else if (result.error) {
        Toast(result.error);
      }
    });

  };

  handleUpdateProperty = (payload, propertyID, currentUser) => {
  
    updateProperty({
      propertyID,
      auth_token: currentUser.auth_token,
      payload
    }).then(result => {
      if (result.status === "success") {
        localStorage.setItem('justUpdated', 'true');
        setTimeout(() => {
          window.location = '/landlord/properties?mode=address';
        }, 500);
      } else if (result.error) {
        Toast(result.error || "Unable to update property");
      }
    });
  
  };

  resetForm = () => {
    this.setState({ 
      newPropertyAddress: {
        line1: "",
        line2: "",
        city: "",
        state: "",
        zip: ""
      },
      newPropertyRentAmount: ""
    });
  };

  handleDeleteProperty = () => {
    const { currentUser } = this.props.context;
    const { propertyID } = this.state;

    deleteProperty({
      property_id: propertyID,
      auth_token: currentUser.auth_token
    }).then(result => {
      if (result.status === "success") {
        window.location = "/landlord/properties?mode=address";
      } else if (result.error) {
        Toast(result.error || "Unable to delete property");
      }
    });
  };

  render() {
    const {
      newPropertyAddress,
      newPropertyRentAmount
    } = this.state;
    
    if (!newPropertyAddress) return null;

    const title = this.state.creating ? 'Add a' : 'Edit';

    return (
      <Wrapper>
        <Link to="/landlord/dashboard" style={{position: 'absolute', left: 20, top: 38}} className="goback">
          <FontAwesome icon="fas fa-chevron-left" size={30} color="#5a53a1" />
        </Link>
        <ViewHeader title={`${title} Property`} />
        <AddPropertyForm>
         <TextField
            className="form_item"
            label="Address line 1"
            value={newPropertyAddress.line1}
            onChange={event =>
              this.setState({ newPropertyAddress: { ...newPropertyAddress, line1: event.target.value }})
            }
            onKeyUp={(e) => { this.listenForEnter(e) }}
          />
          <TextField
            className="form_item"
            label="Address line 2"
            value={newPropertyAddress.line2}
            onChange={event =>
              this.setState({ newPropertyAddress: { ...newPropertyAddress, line2: event.target.value }})
            }
            onKeyUp={(e) => { this.listenForEnter(e) }}
          />
          <TextField
            className="form_item"
            label="City"
            value={newPropertyAddress.city}
            onChange={event =>
              this.setState({ newPropertyAddress: { ...newPropertyAddress, city: event.target.value }})
            }
            onKeyUp={(e) => { this.listenForEnter(e) }}
          />
          <TextField
            id="state"
            className="form_item"
            label="State"
            inputProps={{maxLength: 2}}
            value={newPropertyAddress.state}
            onChange={event =>
              this.setState({ newPropertyAddress: { ...newPropertyAddress, state: event.target.value }})
            }
            onKeyUp={(e) => { this.listenForEnter(e) }}
          />
          <TextField
            className="form_item"
            label="Zip code"
            inputProps={{maxLength: 5}}
            value={newPropertyAddress.zip}
            onChange={event =>
              this.setState({ newPropertyAddress: { ...newPropertyAddress, zip: event.target.value }})
            }
            onKeyUp={(e) => { this.listenForEnter(e) }}
          />
          <TextField
            className="form_item"
            label="Rent Amount (US dollars)"
            inputProps={{maxLength: 7}}
            value={newPropertyRentAmount}
            onChange={event => {
              let num = new RegExp(/^[1-9$]+(,[0-9]+)*/);
              if (3 === event.target.value.split('$').length || event.target.value === '$0') {
                return;
              }
              if(num.test(event.target.value)) {
                let amount = parseInt(event.target.value.replace('$', '').replace(/,/g, ''));
                amount = isNaN(amount) ? '' : amount.toLocaleString();;
                this.setState({ newPropertyRentAmount: '$' + amount })     
              }
            }}
            onKeyUp={(e) => { this.listenForEnter(e) }}
          />
          <Button
            label={`${this.state.creating ? 'Add' : 'Update'} Property`}
            onClick={this.handlePropertyForm}
            style={{ width: "100%", marginTop: 20 }}
          />
          {!this.state.creating && (
            <Button
              label="Remove Property"
              onClick={this.handleDeleteProperty}
              style={{ background: "none", width: "100%", marginTop: 12, color: "#b50202", fontSize: 17}}
            />
          )}
        </AddPropertyForm>
      </Wrapper>
    );  
  }
}

const Wrapper = styled.div`
  min-height: 100%;
  background: #fff;
  .form_item {
    width: 100%;
  }
  .form_item input {
    text-transform: capitalize !important;
  }
  #state {
    text-transform: uppercase !important;
  }
`;

const AddPropertyForm = styled.div`
  border-top: solid 1px #f2f2f2;
  text-align: center;
  padding: 30px 20px 15px;
  background: white;
`;

export default PropertyForm;
