import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import FontAwesome from '../FontAwesome/FontAwesome'
import styled from 'styled-components'
import ViewHeader from '../ViewHeader/ViewHeader'
import PaymentCard from '../PaymentCard/PaymentCard'
import { fetchPayments } from '../../services/AppService'

class TenantPaymentHistory extends Component {
	state = {
		payments: null,
	}

	async componentWillMount() {
		const { auth_token } = this.props.context.currentUser
		const resp = await fetchPayments({ auth_token })
		if (resp.status === 'success') {
			this.setState({ payments: resp.rows })
		}
	}

	render() {
		const { payments } = this.state

		if (!payments) return null

		const hasPayments = payments.length > 0

		return (
			<Wrapper>
				<Link
					to="/tenant/dashboard"
					style={{ position: 'absolute', left: 20, top: 40 }}
					className="goback"
				>
					<FontAwesome icon="fas fa-chevron-left" size={30} color="#5a53a1" />
				</Link>
				<ViewHeader title="Payment History" />
				<div
					style={{
						fontSize: 16,
						maxWidth: 200,
						margin: '-5px auto 20px',
						textAlign: 'center',
						padding: '3px 0',
					}}
					className="payment-amount-dollars"
				>
					All transactions after July 1st 2023 include a $6.99 Processing Fee
				</div>
				{hasPayments &&
					payments.map(payment => (
						<PaymentCard
							key={payment.description}
							mode="tenant"
							payment={payment}
						/>
					))}
				{!hasPayments && <NoneFoundView>No Payment History</NoneFoundView>}
			</Wrapper>
		)
	}
}

const Wrapper = styled.div`
	min-height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: #fff;
`

const NoneFoundView = styled.div`
	height: 120px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-style: italic;
	font-size: 22px;
	opacity: 0.5;
	font-weight: 600;
`

export default TenantPaymentHistory
