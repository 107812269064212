import React from 'react'
import styled from 'styled-components'
import { StripeProvider, Elements } from 'react-stripe-elements'
import moment from 'moment'
import Button from '../Button/Button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import StyledContainer from '../StyledContainer/StyledContainer'
import { withUserHeader } from '../UserHeader/UserHeader'
import PaymentElement from '../PaymentElement/PaymentElement'
import Toast from '../../shared/toast'
import FontAwesome from '../FontAwesome/FontAwesome'
import { submitPayment } from '../../services/AppService'

function TenantPayment(props) {
	const { currentUser } = props.context
	const [amount, setAmount] = React.useState('0')
	const [stripeToken, setStripeToken] = React.useState(null)
	const [paymentInProgress, setPaymentInProgress] = React.useState(false)
	const [paymentState, setPaymentState] = React.useState('payment_incomplete')

	const onSelectNumber = number => {
		// Strip commas.
		let newValue = `${amount.replace(/,/g, '')}${number}`

		// Remove leading 0
		if (newValue[0] === '0' && newValue.length > 1) {
			newValue = newValue.substring(1)
		}

		// Only allow two characters behind the decimal.
		if (newValue.includes('.') && newValue.split('.')[1].length > 2) return

		// Don't allow any value more than $9999
		const leadingDigits = parseInt(newValue.split('.')[0], 10)
		if (leadingDigits > 99999) return

		// Tack a 0 on the front if we are less than $1.
		if (!leadingDigits) {
			newValue = `0${newValue}`
		}

		// Add commas.
		newValue = newValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

		setAmount(newValue)
	}

	const onSelectDecimal = () => {
		// If leading with a decimal, place a 0 in front.
		if (amount === '0') {
			setAmount('0.')
		}

		// Don't allow more than one decimal
		else if (!amount.includes('.')) {
			setAmount(`${amount}.`)
		}
	}

	const onSelectBackspace = () => {
		// Strip commas.
		let newValue = `${amount.replace(/,/g, '')}`

		// Reset to 0 if we delete the last character.
		if (amount.length === 1) {
			setAmount('0')
			return
		}

		newValue = newValue.substr(0, newValue.length - 1)

		// Add commas.
		newValue = newValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

		setAmount(newValue)
	}

	const renderDecimal = () => (
		<Col xs={4}>
			<Btn onClick={() => onSelectDecimal()}>.</Btn>
		</Col>
	)

	const renderBackspace = () => (
		<Col xs={4}>
			<Btn onClick={() => onSelectBackspace()}>˂</Btn>
		</Col>
	)

	const renderNumber = number => (
		<Col xs={4}>
			<Btn onClick={() => onSelectNumber(number)}>{number}</Btn>
		</Col>
	)

	const setToken = token => {
		if (!token) return Toast('We were unable to add this card.')
		setStripeToken(token)
	}

	const confirmPayment = () => {
		const timeNow = moment()
		const lastLoginTime = moment.unix(currentUser.last_login)
		if (timeNow.diff(lastLoginTime, 'minutes') >= 30) {
			Toast('You need to log in again.')
			setTimeout(() => {
				props.context.logout()
			}, 1750)
			return
		}

		if (paymentInProgress) return
		setPaymentInProgress(true)

		// Ensure the amount is above $0
		let amountFloatVal = parseFloat(amount.replace(/,/g, ''))
		if (amountFloatVal <= 0) {
			setPaymentInProgress(false)
			Toast(`Please enter a valid dollar amount.`)
			return
		}

		// Run the charge
		const payload = {
			type: 'stripe',
			token: stripeToken,
			amountInCents: amountFloatVal * 100,
			tenantID: currentUser.user_id,
			landlordID: currentUser.landlord_user_id,
			landlordName: `${currentUser.landlord_first_name} ${currentUser.landlord_last_name}`,
			tenantName: `${currentUser.first_name} ${currentUser.last_name}`,
			propertyAddress: currentUser.property_address,
			propertyID: currentUser.property_id,
			propertyRentAmount: currentUser.rent_amount,
		}

		submitPayment({ payload }).then(result => {
			if (result && result.status === 'success') {
				setPaymentState('payment_success')
				Toast('Payment succeeded!')
			} else {
				Toast(result.error)
				setPaymentInProgress(false)
			}
		})
	}

	if (paymentState === 'payment_success') {
		return (
			<PaymentSuccessView>
				<FontAwesome
					icon="fas fa-check-circle"
					color="white"
					size={48}
					style={{ marginBottom: 20 }}
				/>
				<p
					style={{ fontWeight: 600, opacity: 1, fontSize: 32, marginBottom: 0 }}
				>
					$
					{(parseFloat(amount.replace(/,/g, '')) + 6.99).toLocaleString(
						undefined,
						{ minimumFractionDigits: 2 }
					)}{' '}
					Paid!
					<small
						style={{
							fontSize: 26,
							lineHeight: 'normal',
							display: 'block',
							opacity: '.85',
							textAlign: 'center',
						}}
					>
						Your payment was successful
					</small>
				</p>
				<div
					className="goBack"
					onClick={() => {
						setPaymentState('payment_incomplete')
						setStripeToken(false)
						setAmount(0)
					}}
				>
					All done!
				</div>
			</PaymentSuccessView>
		)
	}

	return (
		<Wrapper>
			<StyledContainer style={{ height: 210, width: '100%' }}>
				<div className="todays-payment-date">
					<span>{moment().format('MMMM Do, YYYY')}</span>
				</div>
				<div className="due_amount">
					${(currentUser.rent_amount / 100).toLocaleString()}
				</div>
				<div className="due_date">Total House Rent</div>
			</StyledContainer>
			<PaymentView>
				<Container>
					<Row className="justify-content-center">
						<div>
							<div className="dollar_symbol">$</div>
							<h1 className="input_amount">
								{!!stripeToken
									? (
											parseFloat(amount.replace(/,/g, '')) + 6.99
									  ).toLocaleString(undefined, { minimumFractionDigits: 2 })
									: amount}
							</h1>
						</div>
					</Row>
					{!stripeToken && (
						<>
							<Row>
								{renderNumber(1)}
								{renderNumber(2)}
								{renderNumber(3)}
							</Row>
							<Row>
								{renderNumber(4)}
								{renderNumber(5)}
								{renderNumber(6)}
							</Row>
							<Row>
								{renderNumber(7)}
								{renderNumber(8)}
								{renderNumber(9)}
							</Row>
							<Row>
								{renderDecimal()}
								{renderNumber(0)}
								{renderBackspace()}
							</Row>
						</>
					)}

					{/* 1. Collect Card Info */}
					{!stripeToken && (
						<Row>
							<div className="payment-element-wrapper">
								<StripeProvider
									apiKey={process.env.REACT_APP_STRIPE_PUBLIC_KEY}
								>
									<Elements>
										<PaymentElement
											smallPhone={window.outerWidth < 375 ? true : false}
											setToken={setToken}
										/>
									</Elements>
								</StripeProvider>
							</div>
						</Row>
					)}

					{/* 2. Submit Payment */}
					{!!stripeToken && (
						<>
							<Row style={{ maxWidth: 315 }}>
								<p className="lptax">Includes a $6.99 Processing Fee</p>
								<Button
									label="Confirm Payment"
									stretch
									mega
									toggleLabel
									style={{
										borderTopRightRadius: 0,
										borderTopLeftRadius: 0,
										background: '#4036ad',
										padding: '0 25px',
										height: 52,
										fontSize: 18,
									}}
									onClick={confirmPayment}
									loading={paymentInProgress}
									disabled={paymentInProgress}
								/>
							</Row>
							<Row>
								<div
									className="goBack"
									onClick={() => {
										setStripeToken(false)
										setAmount(amount)
									}}
								>
									Go back
								</div>
							</Row>
						</>
					)}
				</Container>
			</PaymentView>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: #160d70;
	border-bottom: 1000px solid 420px solid #160d70;
	color: white;
	position: relative;

	p.lptax {
		text-align: center;
		width: 100%;
		border: solid 1px rgba(255, 255, 255, 0.25);
		padding: 7px 0;
		margin-bottom: 0;
		border-bottom: none;
		opacity: 1;
		border-top-right-radius: 4px;
		border-top-left-radius: 4px;
	}

	.todays-payment-date {
		position: absolute;
		top: 40px;
		right: 25px;
		font-size: 16px;
	}

	.due_amount {
		padding-top: 85px;
		font-size: 32px;
		font-weight: 600;
		margin-bottom: 3px;
	}

	.due_date {
		background: #0e0949;
		padding: 5px 12px;
		font-size: 16px;
		letter-spacing: 0.3px;
		border-radius: 4px;
	}

	.input_amount {
		height: 100px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 72px;
	}

	.dollar_symbol {
		position: absolute;
		font-size: 27px;
		margin-left: -20px;
		margin-top: 10px;
	}

	.goBack {
		display: block;
		width: 100%;
		margin: 40px auto 0;
		padding: 10px 0;
		border: solid 1px #4f4d94;
		border: solid 1px rgba(255, 255, 255, 0.25);
		border-radius: 4px;
		max-width: 120px;
		cursor: pointer;
	}
`

const PaymentView = styled.div`
	text-align: center;
	padding: 20px;

	.payment-element-wrapper {
		width: 100%;
		margin-top: 30px;
	}
`

const Btn = styled.div`
	box-sizing: border-box;
	height: 90px;
	width: 90px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	font-size: 27px;
	font-weight: 600;
	cursor: pointer;
	transition: 0.2s all;
	max-width: 100%;

	&:hover {
		transform: scale(1.4);
	}
`

const PaymentSuccessView = styled.div`
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #160d70;
	color: white;
	padding: 30px;
	text-align: center;

	.goBack {
		display: block;
		width: 100%;
		margin: 40px auto 0;
		padding: 10px 0;
		border: solid 1px #4f4d94;
		border: solid 1px rgba(255, 255, 255, 0.25);
		border-radius: 4px;
		max-width: 120px;
		cursor: pointer;
	}
`

export default withUserHeader(TenantPayment)
