import React from 'react';
import { Link } from 'react-router-dom';
import FontAwesome from "../FontAwesome/FontAwesome";
import styled from 'styled-components';
import ViewHeader from '../ViewHeader/ViewHeader';

function TenantSupport(props) {
  return (
    <Wrapper>
      <Link to="/tenant/dashboard" style={{position: 'absolute', left: 20, top: 38}} className="goback">
        <FontAwesome icon="fas fa-chevron-left" size={30} color="#5a53a1" />
      </Link>
      <ViewHeader title="Support & Help" />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
`;

export default TenantSupport;
