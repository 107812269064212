// 9/2: this file will need the property form components to be updated with the new form when I finish it.

import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Home from "../ui/Home/Home";
import HowItWorks from "../ui/HowItWorks/HowItWorks";
import LandlordOAuth from "../ui/StripeOAuth/LandlordOAuth";
import landlordStripeAccountCreated from "../ui/StripeOAuth/LandlordStripeAccountCreated";
import landlordStripeAccountError from "../ui/StripeOAuth/LandlordStripeAccountError";
import LandlordDashboard from "../ui/LandlordDashboard/LandlordDashboard";
import LandlordPaymentHistoryProperties from "../ui/LandlordPaymentHistory/LandlordPaymentHistoryProperties";
import LandlordPaymentHistoryMonthYear from "../ui/LandlordPaymentHistory/LandlordPaymentHistoryMonthYear";
import LandlordPaymentHistory from "../ui/LandlordPaymentHistory/LandlordPaymentHistory";
import LandlordProperties from "../ui/LandlordProperties/LandlordProperties";
import PropertyForm from "../ui/Forms/PropertyForm";
import LandlordTenants from "../ui/LandlordTenants/LandlordTenants";
import TenantForm from "../ui/Forms/TenantForm";
import TenantAddedSuccess from "../ui/LandlordTenants/TenantAddedSuccess";
import SignIn from "../ui/SignIn/SignIn";
import PersonalInfo from "../ui/PersonalInfo/PersonalInfo";
import RegistrationSuccess from "../ui/RegistrationSuccess/RegistrationSuccess";
import SetPassword from "../ui/SetPassword/SetPassword";
import ResetPassword from "../ui/ResetPassword/ResetPassword";
import ForgotPassword from "../ui/ForgotPassword/ForgotPassword";
import PasswordResetSuccess from "../ui/PasswordResetSuccess/PasswordResetSuccess";
import PrivacyPolicy from "../ui/PrivacyPolicy/PrivacyPolicy";
import TermsOfUse from "../ui/TermsOfUse/TermsOfUse";
import SignUp from "../ui/SignUp/SignUp";
import TenantContactLandlord from "../ui/TenantContactLandlord/TenantContactLandlord";
import TenantDashboard from "../ui/TenantDashboard/TenantDashboard";
import TenantPayment from "../ui/TenantPayment/TenantPayment";
import AuthnetTenantPayment from "../ui/TenantPayment/AuthnetTenantPayment";
import TenantPaymentHistory from "../ui/TenantPaymentHistory/TenantPaymentHistory";
import TenantSupport from "../ui/TenantSupport/TenantSupport";
import { PaymentReceipt } from "../ui/PaymentReceipt/PaymentReceipt";
import { AppProvider, AppContext } from "../providers/AppProvider";
import { validateUser } from "../services/AppService";
import { withGlobal } from "../ui/Global/Global";
import ScrollToTop from "./ScrollToTop";

function AppRoutes() {  
  const AppRoute = (path, component, options = {}) => (
    <Route exact path={path} component={withGlobal(component, options)} />
  );

  let auth_token = localStorage.getItem(process.env.REACT_APP_SESSION_TOKEN);

    return (
    <Router>
      <ScrollToTop>
        <AppProvider>
          <AppContext.Consumer>
            {context => {
              const currentUser = context.currentUser || {};
              // Attempt to restore session if a token is available.
              if (!currentUser.type && auth_token) {
                validateUser({ auth_token: auth_token }).then(result => {
                  if (result && result.status === "success" && result.isValid) {
                    context.setData({
                      currentUser: result.user,
                      isLoggedIn: true
                    });
                    localStorage.setItem('isLoggedIn', 'true');
                  } else {
                    auth_token = null;
                    context.setData({ isLoggedIn: false });
                    localStorage.setItem('isLoggedIn', 'false');
                    localStorage.removeItem(process.env.REACT_APP_SESSION_TOKEN);
                    window.location = "/home-redirect";
                  }
                });
              }

              /* Landlord Routes */
              if (currentUser.type === "landlord") {
                return (
                  <Switch>
                    {AppRoute("/landlord/properties", LandlordProperties)}
                    {AppRoute(
                      "/landlord/property/:propertyID/update",
                      PropertyForm
                    )}
                    {AppRoute(
                      "/landlord/property/:formID/add",
                      PropertyForm
                    )}
                    {AppRoute(
                      "/landlord/payment-history",
                      LandlordPaymentHistoryProperties
                    )}
                    {AppRoute(
                      "/landlord/payment-history/:propertyID",
                      LandlordPaymentHistoryMonthYear
                    )}
                    {AppRoute(
                      "/landlord/payment-receipt/:receiptID",
                      PaymentReceipt
                    )}
                    {AppRoute(
                      "/landlord/payment-history/:propertyID/:month/:year",
                      LandlordPaymentHistory
                    )}
                    {AppRoute(
                      "/landlord/property/:propertyID/tenants",
                      LandlordTenants
                    )}
                    {AppRoute(
                      "/landlord/property/:propertyID/tenants/add",
                      TenantForm
                    )}
                    {AppRoute(
                      "/landlord/property/:propertyID/:tenantID/edit",
                      TenantForm
                    )}
                    {AppRoute("/landlord/property/:propertyID/tenant/added", TenantAddedSuccess)}
                    {AppRoute("/landlord/info", PersonalInfo)}
                    {AppRoute("*", LandlordDashboard)}
                  </Switch>
                );
              }

              /* Tenant Routes */
              if (currentUser.type === "tenant") {
                return (
                  <Switch>
                    {AppRoute("/tenant/contact-landlord", TenantContactLandlord)}
                    {AppRoute("/tenant/payment", currentUser.landlord_user_id === 672 ? AuthnetTenantPayment : TenantPayment)}
                    {AppRoute("/tenant/payment-history", TenantPaymentHistory)}
                    {AppRoute("/tenant/payment-receipt/:receiptID", PaymentReceipt)}
                    {AppRoute("/tenant/info", PersonalInfo)}
                    {AppRoute("/tenant/support", TenantSupport)}
                    {AppRoute("*", TenantDashboard)}
                  </Switch>
                );
              }

              /* Default Routes */
              if (!context.isLoggedIn && localStorage.isLoggedIn !== 'true') {
                return (
                  <Switch>
                    {/* {AppRoute("/privacy-policy", PrivacyPolicy)}
                    {AppRoute("/terms-of-use", TermsOfUse)} */}
                    {AppRoute("/landlord/oAuth", LandlordOAuth)}
                    {AppRoute("/landlord/stripe-account/created", landlordStripeAccountCreated)}
                    {AppRoute("/landlord/stripe-account/error", landlordStripeAccountError)}
                    {AppRoute("/account/create-password", SetPassword)}
                    {AppRoute("/account/reset-password", ResetPassword)}
                    {AppRoute("/landlord/registered", RegistrationSuccess)}
                    {AppRoute("/password-reset-success", PasswordResetSuccess)}
                    {AppRoute("/forgot-password", ForgotPassword)}
                    {AppRoute("/privacy-policy", PrivacyPolicy)}
                    {AppRoute("/terms-of-use", TermsOfUse)}
                    {AppRoute("/sign-up", SignUp)}
                    {AppRoute("/sign-in", SignIn)}
                    {AppRoute("/how-it-works", HowItWorks)}
                    {AppRoute("*", Home)}
                  </Switch>
                );
              }
            }}
          </AppContext.Consumer>
        </AppProvider>
      </ScrollToTop>
    </Router>
  );
}

export default AppRoutes;
