import React, { Component } from 'react';
import { string, number, bool, func, object } from 'prop-types';

import styled from 'styled-components';

export default class FontAwesome extends Component {
  static propTypes = {
    boxSize: number,
    color: string,
    colorHover: string,
    gapRight: number,
    icon: string,
    nudge: bool,
    nudgeLeft: bool,
    nudgeRight: bool,
    onClick: func,
    padY: number,
    size: number,
    spin: bool,
    style: object,
    subtle: bool,
    white: bool,
  };

  static defaultProps = {
    clearance: 5,
    color: 'rgba(0,0,0,.36)',
    colorHover: 'rgba(0,0,0,.36)',
    gapBottom: 0,
    gapLeft: 0,
    gapRight: 0,
    gapTop: 0,
    icon: 'fa fa-star',
    size: 16,
    spin: false,
    style: {},
    subtle: false,
  };

  render() {
    const {
      onClick,
      boxSize,
      size,
      clearance,
      subtle,
      white,
      color,
      nudge,
      nudgeRight,
      gapRight,
      gapBottom,
      gapTop,
      nudgeLeft,
      gapLeft,
      style,
      icon,
      spin,
      colorHover,
    } = this.props;

    return (
      <Wrapperstyle colorHover={colorHover} color={color} white={white} subtle={subtle}>
        <div
          onClick={() => onClick && onClick()}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: boxSize ? boxSize : size + clearance,
            height: boxSize ? boxSize : size + clearance,
            fontSize: size,
            marginRight: nudge || nudgeRight ? 12 : gapRight,
            marginBottom: gapBottom,
            marginTop: gapTop,
            marginLeft: nudgeLeft ? 12 : gapLeft,
            ...style,
          }}
        >
          <i className={`${icon}${spin ? ' fa-spin' : ''}`} />
        </div>
      </Wrapperstyle>
    );
  }
}

const Wrapperstyle = styled.div`
  color: white;
  transition: .2s all;

  ${props =>
    props.color &&
    `
    color: ${props.color};
  `}

  ${props =>
    props.subtle &&
    `
    color: rgb(187, 187, 187);
  `}

  ${props =>
    props.white &&
    `
    color: white;
  `}
`;
