import React from "react";
import styled from "styled-components";
import moment from "moment";
import StyledContainer from "../StyledContainer/StyledContainer";
import SimpleImage from "../SimpleImage/SimpleImage";
import Logo from "../Logo/Logo";
import { Link } from "react-router-dom";

function LandlordDashboard(props) {
  const { currentUser } = props.context;
  const photoURL = currentUser.photo || "/images/default_user.jpg";

  const DashSection = ({ title, content, url }) => (
    <Link to={url}>
      <Section className='dash-section' style={{ marginBottom: 16 }}>
        <h3>{title}</h3>
        <span>{content}</span>
      </Section>
    </Link>
  );

  return (
    <>
      <Container>
        <StyledContainer style={{ height: 210 }}>
          <SimpleImage
            context={props.context}
            upload
            circle
            framed
            src={photoURL}
            style={{ opacity: 0.85, width: 76, height: 76, marginBottom: 10 }}
          />
          <div className="xl label_name">
            {currentUser.first_name} {currentUser.last_name}
          </div>
          <div className="label_title">{currentUser.email}</div>
        </StyledContainer>
        <Section transparent>
          <h3 style={{fontSize: 18}}>Today</h3>
          <div>
            <span style={{fontSize: 18}}>{moment().format("MMMM Do, YYYY")}</span>
          </div>
        </Section>

        {DashSection({
          title: "Payment History",
          content: "View recieved payments",
          url: "/landlord/payment-history"
        })}

        {DashSection({
          title: "Manage Properties",
          content: "Add or remove properties",
          url: "/landlord/properties?mode=address"
        })}

        {DashSection({
          title: "Manage Tenants",
          content: "Add or remove tenants",
          url: "/landlord/properties?mode=tenant"
        })}

        {DashSection({
          title: "Personal Information",
          content: "Name, phone, email",
          url: "/landlord/info"
        })}

        <Section transparent>
          <Footer>
            <Logo flat size={48} style={{ marginBottom: 20 }} />
            <div className="menu_item">
              <Link to="/landlord/faq">FAQ</Link>
            </div>
            <div className="menu_item">
              <div
                onClick={() =>
                  window.open("https://dashboard.stripe.com/invoices", "_blank")
                }
                style={{ cursor: "pointer" }}
              >
                <p style={{margin: 0}}>View Stripe Account</p>
              </div>
            </div>
            <div onClick={() => props.context.logout()}>
              <p>Sign Out</p>
            </div>
          </Footer>
        </Section>
      </Container>
    </>
  );
}

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;

  p {
    font-size: 15px;
  }

  span {
    font-size: 16px;
  }

  .label_name {
    padding: 5px 0 0;
    font-size: 20px;
  }

  .label_title {
    font-size: 16px;
  }
`;

const Section = styled.div`
  text-align: center;
  padding: 35px 20px 30px;
  background: white;
  color: rgba(0, 0, 0, 0.7);

  ${props =>
    props.transparent &&
    `
    background: none;
  `}
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  .menu_item {
    margin-bottom: 12px;
  }
  a,
  p {
    color: rgba(0, 0, 0, 0.7);
    opacity: 1;
    cursor: pointer
    font-size: 16px;
  }
`;

export default LandlordDashboard;
