import React from "react";
import styled from "styled-components";
import Container from 'react-bootstrap/Container';
import StyledContainer from "../StyledContainer/StyledContainer";
import Logo from "../Logo/Logo";

export default function landlordStripeAccountCreated() {
  return (
    <>
      <StyledContainer style={{ background: '#f7f7f7', minHeight: "100vh" }}>
        <Content>
          <Container>
            <Logo flat size={60} style={{ margin: "0 auto 40px" }}/>
            <h2 style={{fontSize: 36}}>Great Job!</h2>
            <View>
              <p style={{fontSize: 22}}>Your Stripe Account has been set up. Your tenants will be able to pay you fast and easy!</p>
            </View>
            <View>
              <h3>Next steps...</h3>
              <p style={{fontSize: 22}}>Return to the <strong style={{fontWeight:600}}>first email</strong> you received from us and complete <strong style={{fontWeight:600}}>step 2</strong> to create a password and access your new account!</p>
              <p style={{marginTop: 30}}>That's it! You can close this window.</p>
            </View>
            <View style={{marginTop: 30, borderTop: 'solid 1px rgba(255,255,255,.25)'}}>
              <p>Need support? Email us:<br/><a href="mailto:support@little-payments.com">support@little-payments.com</a></p>
            </View>
          </Container>
        </Content>
      </StyledContainer>
    </>
  );
}

const Content = styled.div`
  max-width: 640px;
  margin-top: 100px;
  text-align: center;
  width: 100%;

  * {
    color: rgba(0,0,0,0.7);
  }

  a {
    color: #015c52;
  }

  h3 {
    opacity: 1;
    margin: 25px 0 0;
    font-size: 28px;
  }

  p {
    margin: 20px 0;
  }

  @media all and (max-width: 767px) {
    margin-top: 80px;
  }

  img {
    max-width: 70px;
    margin: 0 auto;
  }
`;

const View = styled.div`
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
`;