import React from 'react';
import { Link } from 'react-router-dom';
import FontAwesome from "../FontAwesome/FontAwesome";
import styled from 'styled-components';
import PersonalInfoForm from '../Forms/PersonalInfoForm';
import ViewHeader from '../ViewHeader/ViewHeader';

function PersonalInfo(props) {
  return (
    <Wrapper>
      <Link to="/landlord/dashboard" style={{position: 'absolute', left: 20, top: 38}} className="goback">
        <FontAwesome icon="fas fa-chevron-left" size={30} color="#5a53a1" />
      </Link>
      <ViewHeader title="Personal Information" />
      <FormContainer>
        <PersonalInfoForm context={props.context} />
      </FormContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
`;

const FormContainer = styled.div`
  background: white;
  width: 100%;
  padding: 0 25px 25px;
`;

export default PersonalInfo;
