import request from "./request";

export function createLandlord({ payload }) {
  return request({
    url: `/account/create-landlord`,
    method: "POST",
    data: {
      payload
    }
  });
}

export function createTenant({ payload }) {
  return request({
    url: `/account/create-tenant`,
    method: "POST",
    data: {
      payload
    }
  });
}

export function createTenantRegistration({ email, house_code }) {
  return request({
    url: `/account/create-tenant-registration`,
    method: "POST",
    data: {
      email,
      house_code
    }
  });
}

export function setPassword({ email, auth_token, password }) {
  return request({
    url: `/account/set-password`,
    method: "POST",
    data: {
      email,
      auth_token,
      password
    }
  });
}

export function resetPassword({ email, password }) {
  return request({
    url: `/account/reset-password`,
    method: "POST",
    data: {
      email,
      password
    }
  });
}

export function checkActivated({ email }) {
  return request({
    url: `/account/check-activated`,
    method: "POST",
    data: {
      email
    }
  });
}

export function sendPasswordReset({ email, base64Email }) {
  return request({
    url: `/account/send-password-reset`,
    method: "POST",
    data: {
      email,
      base64Email
    }
  });
}

export function login({ email, password }) {
  return request({
    url: `/account/login`,
    method: "POST",
    data: {
      email,
      password
    }
  });
}

export function logout({ auth_token }) {
  return request({
    url: `/account/logout`,
    method: "POST",
    data: { auth_token }
  });
}

export function validateUser({ auth_token }) {
  return request({
    url: `/account/validate`,
    method: "POST",
    data: { auth_token }
  });
}

export function updateUser({ payload }) {
  return request({
    url: `/account/update-user`,
    method: "POST",
    data: { payload }
  });
}

export function saveUserPhoto({ payload }) {
  return request({
    url: `/account/save-user-photo`,
    method: "POST",
    data: { payload }
  });
}

export function deleteUser({ payload }) {
  return request({
    url: `/account/delete-user`,
    method: "POST",
    data: { payload }
  });
}

export function tenantsForLandlord({ landlordID, propertyID }) {
  return request({
    url: `/landlords/${landlordID}/tenants/${propertyID}`,
    method: "GET"
  });
}

export function updateProperty({ propertyID, auth_token, payload }) {
  return request({
    url: `/properties/${propertyID}/update`,
    method: "POST",
    data: {
      auth_token,
      payload
    }
  });
}

export function deleteProperty({ property_id, auth_token }) {
  return request({
    url: `/properties/${property_id}/delete`,
    method: "POST",
    data: {
      auth_token
    }
  });
}

export function createProperty({ payload }) {
  return request({
    url: `/properties/create`,
    method: "POST",
    data: {
      payload
    }
  });
}

export function propertiesForLandlordID(landlordID) {
  return request({
    url: `/landlords/${landlordID}/properties`,
    method: "GET"
  });
}

export function getPropertyWithID(landlordID, propertyID) {
  return request({
    url: `landlords/${landlordID}/property/${propertyID}`,
    method: "GET"
  });
}

export function saveLandlordStripeID(payload) {
  return request({
    url: `/account/save/landlord-stripe-id`,
    method: "POST",
    data: {
      payload
    }
  });
}

export function submitPayment({ payload }) {
  return request({
    url: `/account/payment/submit`,
    method: "POST",
    data: {
      payload
    }
  });
}

export function fetchPayments({ auth_token }) {
  return request({
    url: `/account/payment/fetch-all`,
    method: "POST",
    data: { auth_token }
  });
}
